import { createGlobalStyle } from "styled-components";

// ICONS AND IMAGES
import search_icon from "../assets/icons/search.svg";
import clear_icon from "../assets/icons/clear.svg";
import logo_mask from "../assets/images/logo-mask.svg";
import red_camp_mark from "../assets/images/red-camp-mark.jpg";
import blue_camp_mark from "../assets/images/blue-camp-mark.jpg";
import mixed_camp_mark from "../assets/images/mixed-camp-mark.jpg";
import team_mark from "../assets/images/team-mark.svg";

export const GlobalStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Orbitron');
    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap rel=stylesheet');

    @font-face {
        font-family: "Agency FB Bold";
        font-style: normal;
        font-weight: 700;
        src: local("Agency FB Bold"), local("AgencyFB-Bold"),
          url(https://allfont.net/cache/fonts/agency-fb-bold_cee84847c4ab16cf2b0952d063712724.woff)
            format("woff"),
          url(https://allfont.net/cache/fonts/agency-fb-bold_cee84847c4ab16cf2b0952d063712724.ttf)
            format("truetype");
      }

    //${({ theme }) => theme.text};


    :root {

    .pt-3{
       padding-top: 0 !important;
     }

        .c-wrapper {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            min-width: 0;
            height: 98vh;
            overflow: hidden;
        }

        .medium-editor-toolbar {
            background: #363636 !important;
            background-color: #363636 !important;
            border: solid 2px #419e7e !important;
        }

        .medium-editor-toolbar li button {
            background: #363636 !important;
            background-color: #363636 !important;
        }

        .medium-editor-toolbar li button:hover {
            color: #3d8e72 !important;
        }

        .medium-editor-toolbar li button:active {
            color: #48bd94 !important;
        }

        .medium-toolbar-arrow-under:after, .medium-toolbar-arrow-over:before {
            border-style: solid;
            content: '';
            display: block;
            height: 0;
            left: 0 !important;
            margin-left: 0 !important;
            position: absolute;
            width: 0; }
          
          .medium-toolbar-arrow-under:after {
            border-width: 0 !important;
          }
          
          .medium-toolbar-arrow-over:before {
            border-width: 0 !important;
            top: 0 !important; }

        background: "${({ theme }) => theme.base_background}";
        background-color: ${({ theme }) => theme.base_background};
        //overflow: hidden;
        div {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            letter-spacing: 0.25px;
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        
        ::-webkit-scrollbar:vertical {
            width: 4px;
        }
        
        ::-webkit-scrollbar:horizontal {
            height: 0px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 8px;
            //border: 2px solid white; /* should match background, can't be transparent */
            background-color: rgba(white, 0.2);  
        }

    }

    //height: 400px;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        
    }
    
    ::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    ::-webkit-scrollbar:horizontal {
        height: 0px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        //border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(white, 0.2);
        
    }


   
    .c-app {

        /* 
        GLOBALS 
        */

        --primary: #321fdb;
        --secondary: #ced2d8;
        --success: #2eb85c;
        --info: #39f;
        --warning: #f9b115;
        --danger: #e55353;
        --light: #ebedef;
        --dark: #636f83;
        //--cympire: #49bd95;
        color: #fff;
        --color: #fff;
        // CYMPIRE
        --blue-cympire: #3BA3F8;

       
        background-color: #131313;
        

        .c-body{
            background: #131313;
            // background: ${({ theme }) => theme.background};
            color: ${({ theme }) => theme.text};
        }

        a {
            color: ${({ theme }) => theme.link};
            text-decoration: none;
        }
        a:hover {
            color: ${({ theme }) => theme.link_hover};
            text-decoration: none;
        }

        div {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            letter-spacing: 0.25px;
        }

        text:focus {
            outline: unset !important;
        }

        :focus {
            outline: unset !important;
        }




   
        .padding-top-30 {
            padding-top: 30px;
        }

        //avatar
        .user-avatar {
            margin-top: 0px;
            width: 48px;
            height: 48px;
        }
    


        /* BUTTONS */
        .btn-link {
            font-weight: 400;
            color: ${({ theme }) => theme.button};
            text-decoration: none;
        }
        .btn-link:hover {
            font-weight: 400;
            color: ${({ theme }) => theme.button};
            opacity: 0.8;
    
        }
        .btn-primary {
            color: #fff;
            background-color: ${({ theme }) => theme.button};
            border-color: ${({ theme }) => theme.button};
        }
        .btn-primary:hover {
            color: #fff;
            background-color: ${({ theme }) => theme.button};
            border-color: ${({ theme }) => theme.button};
            opacity: 0.8;
        }
        .btn-primary:focus {
            color: #fff;
            background-color: ${({ theme }) => theme.button};
            border-color: ${({ theme }) => theme.button};
            box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.button_shadow};
        }
    
        .btn-primary:active {
            color: #fff;
            background-color: ${({ theme }) => theme.button};
            border-color: ${({ theme }) => theme.button};
            box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.button_shadow};
        }
    
        .btn-primary:not(:disabled):not(.disabled):active {
            color: #fff;
            background-color: ${({ theme }) => theme.button};
            border-color: ${({ theme }) => theme.button};
            box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.button_shadow};
        }
        .btn-primary:active:not(:disabled):not(.disabled):active {
            color: #fff;
            background-color: ${({ theme }) => theme.button};
            border-color: ${({ theme }) => theme.button};
            box-shadow: unset;
        }

        .btn-info {
            color: ${({ theme }) => theme.btn_info_text};
            background-color: ${({ theme }) => theme.btn_info};
            border-color: ${({ theme }) => theme.btn_info_border};
        }
    

        .btn-light {
            color: ${({ theme }) => theme.btn_light_text};
            background-color: ${({ theme }) => theme.btn_light};
            border-color: ${({ theme }) => theme.btn_light_border};
        }
        .btn-light:hover {
            color: ${({ theme }) => theme.btn_light_hover};
            background-color: ${({ theme }) => theme.btn_light};
            border-color: ${({ theme }) => theme.btn_light_hover};
        }
        .btn-light:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
            color: ${({ theme }) => theme.btn_light_hover};
            background-color: ${({ theme }) => theme.btn_light};
            border-color: ${({ theme }) => theme.btn_light_hover};
        }



        .btn-danger {
            color: ${({ theme }) => theme.btn_danger_text};
            background-color: ${({ theme }) => theme.btn_danger};
            border-color: ${({ theme }) => theme.btn_danger_border};
        }
        .btn-danger:hover {
            color: ${({ theme }) => theme.btn_danger_hover};
            background-color: ${({ theme }) => theme.btn_danger};
            border-color: ${({ theme }) => theme.btn_danger_hover};
        }
        .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
            color: ${({ theme }) => theme.btn_danger_hover};
            background-color: ${({ theme }) => theme.btn_danger};
            border-color: ${({ theme }) => theme.btn_danger_hover};
        }
    
    
        // buttons grid
        .buttons {
            //padding: 0px;
            //margin: 0px;
            margin-top: 8px;
            margin-bottom: 10px;
            //padding-right: 0px;
            //margin-right: 0px;
        }
        .add-button {
            color: ${({ theme }) => theme.add_button_text};
            background-color: ${({ theme }) => theme.add_button};
            border-color: ${({ theme }) => theme.add_button_border};
        }
        .add-button:hover{
            color: ${({ theme }) => theme.add_button_text};
            background-color: ${({ theme }) => theme.add_button};
            border-color: ${({ theme }) => theme.add_button_border};
            opacity: 0.8;
        }
        .add-button:focus {
            color: ${({ theme }) => theme.add_button_text};
            background-color: ${({ theme }) => theme.add_button};
            border-color: ${({ theme }) => theme.add_button_border};
            //box-shadow: 0 0 0 0.2rem rgba(#152433, 0.25);
            box-shadow: unset;
        }
        .add-button:active {
            color: ${({ theme }) => theme.add_button_text};
            background-color: ${({ theme }) => theme.add_button};
            border-color: ${({ theme }) => theme.add_button_border};
            //box-shadow: 0 0 0 0.2rem rgba(#152433, 0.25);
            box-shadow: unset;
        }
        .add-button:active:not(:disabled):not(.disabled):active {
            color: ${({ theme }) => theme.add_button_text};
            background-color: ${({ theme }) => theme.add_button};
            border-color: ${({ theme }) => theme.add_button_border};
            box-shadow: unset;
        }
    
        .edit-button {
            color: ${({ theme }) => theme.edit_button};
        }
    
        .edit-button:hover {
            color: ${({ theme }) => theme.edit_button_hover};
        }
    
        .btn:focus, .btn.focus {
            outline: 0;
            box-shadow: unset;
        }
    
    


        /* LOADER */
        .loader{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
        }
    


        /* HEADER */ 
        .menus{
            display:flex;
            align-items:center;
            justify-content:flex-end;
            height: 100%;

        }
    
        .c-header{
            background-color: #131313;
            // background-color: ${({ theme }) => theme.header};
            color: ${({ theme }) => theme.header_text};
            border-bottom: 2px solid ${({ theme }) => theme.header_border};
            height: 52px;
            min-height: 52px;
            align-content: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            
            .c-header-toggler-icon {
                margin-top: 3px;
                margin-right: 10px;
                height: 32px;
                background-image: url(${logo_mask});
            }
            .c-header-toggler-icon:hover{
                background-image: url(${logo_mask});
                opacity: 0.5;
            }
            .c-header-nav .c-header-nav-link {
                color: ${({ theme }) => theme.header_link};
            }
            .c-header-nav .c-header-nav-link:hover {
                color: ${({ theme }) => theme.header_link_hover};
            }

            .c-header-activity-indicator-cont{
                max-width: 168px;
                min-width: 122px;
                height: 64px;
                width: 15%;
                display: flex;
                align-content: flex-start;
               // margin-left: 14%;
                position: absolute;
                top: 0;
                margin: 0 auto;
                left: 50%;
                transform: translateX(-50%);
            }
            @media screen and (max-width: 890px) {
                .c-header-activity-indicator-cont{
                    margin-left: unset !important;
                }
            }
            @media screen and (max-width: 630px) {
                .c-header-activity-indicator-cont{
                    display: none !important;
                }
            }
            .c-header-activity-indicator{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                width: 100%;
                height: 100%;
                background: rgb(19,19,19);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                box-shadow: 0 1px 10px rgba(23, 191, 131, 0.8);
                & .icon{
                   color: rgba(23, 191, 131, 0.8);
                   & :hover{
                    color: rgba(23, 191, 131, 1);
                   }
                }
                & .score{
                    padding: 3px;
                    font-size: 1.1rem;
                    font-weight: 500;
                    position: absolute;
                    bottom: 8px;
                }
            }
            .clock{
                position:relative;
                font-family: 'Roboto', sans-serif;
                font-size: 0.8rem;
                text-align: right;
                color: rgba(255, 255, 255, 0.5);
                padding: 0 14px 0 14px;
            }
            .header-buttons {
                position:relative;
                display:flex;
                align-items:center;
                justify-content:flex-end;
                height: 100%;
            }
            .header-ar-switch-cont{
                display:flex;
                align-items:center;
                justify-content:center;
            }
            .mode-blog-campaign-studio{
                position:relative;
                display:flex;
                align-items:center;
                height: 100%;
                left:300px;
            }
            .c-header-avatar-placeholder{
                position:relative;
                width:24px;
                height:24px;
                display: flex;
                align-items: center;
                justify-content: center;
             }
             .c-header-avatar-img{
                 width: 100% !important;
                 height: 100%  !important;
                 border-radius: 50%;
                 object-fit: cover;
             }
        }
        .dropdown-menu {
            background-color: ${({ theme }) => theme.header_dropdown};
            border-color: ${({ theme }) => theme.header_dropdown_border};
        }
        .bg-light {
            background-color: ${({ theme }) =>
              theme.header_dropdown_header} !important;
        }
        .dropdown-item {
            color: ${({ theme }) => theme.header_dropdown_item_text}
        }

        .dropdown-item:hover {
            background-color: ${({ theme }) => theme.header_dropdown_item}
        }

        .dropdown-header:focus {
            outline: none
        }        

        .dropdown-divider {
            height: 0;
            margin: 0.5rem 0;
            overflow: hidden;
            border-top: 1px solid ${({ theme }) =>
              theme.header_dropdown_divider};
        }
    

        // header-info-active-campaign-Idle
        .c-app .c-wrapper .header-info-active-campaign-Idle {
            border-left:7px solid #ffffff;
        }

        // header-info-active-campaign-Active
        .c-app .c-wrapper .header-info-active-campaign-Active {
            border-left:7px solid rgba(#49bd95, 0.7);
        }


        // header-info-active-campaign-Setup
        .c-app .c-wrapper .header-info-active-campaign-Setup {
            border-left: 7px solid #3399ff;
        }
       
        .support-button {
            float: right;
            //width: 100%;
            font-family: 'Orbitron', sans-serif;
            color: rgba(255,255,255, 0.8);
            font-size: 10px;
            background-color: transparent;
            margin-left: 8px;
            
            .support-button-image {
                width: 16px;
                opacity: 0.55;
            }
    
            .support-button-image:hover {
                width: 16px;
                opacity: 0.8;
            }
    
        }
    
        .support-vline {
            border-right: 1px solid rgba(255,255,255, 0.3);
            width: 10px; 
            margin-top: 16px;
            margin-bottom: 16px;
            padding-right: 0px;
            margin-left: 8px;
        }

        /* SUB HEADER */
        .c-subheader{
            background-color: ${({ theme }) => theme.subheader};
            opacity: 0.9;
            .breadcrumb-item{
                color: ${({ theme }) => theme.subheader_breadcrumb_item};
            }


            min-height: unset;
            height: 32px;
            ${({ theme }) => theme.subheader_style}
    
            border-top: 1px solid;
            ${({ theme }) => theme.subheader_border_style}




        }
        .c-subheader-nav {
            min-height: unset;
        }
        .c-subheader .c-subheader-nav .c-subheader-nav-link {
            color: ${({ theme }) => theme.subheader_link};
        }
        .c-subheader .c-subheader-nav .c-subheader-nav-link:focus {
            color: ${({ theme }) => theme.subheader_link};
        }
        .c-subheader .c-subheader-nav .c-subheader-nav-link:hover {
            color: ${({ theme }) => theme.subheader_link_hover};
        } 
        
        


        /* FOOTER */
        .c-footer {
            background-color: ${({ theme }) => theme.footer};
            border-top: 0px solid ${({ theme }) => theme.footer_border};
        }



        /* BADGES */
        .badge-cympire {
            color: ${({ theme }) => theme.badge_text};
            background-color: ${({ theme }) => theme.badge};    
        }
        .badge-red {
            color: ${({ theme }) => theme.badge_red_text};
            background-color: ${({ theme }) => theme.badge_red};    
        }

        .badge-success {
            color: ${({ theme }) => theme.badge_success_text};
            background-color: ${({ theme }) => theme.badge_success};    
        }
        .badge-danger {
            color: ${({ theme }) => theme.badge_danger_text};
            background-color: ${({ theme }) => theme.badge_danger_text};    
        }

        // Severity
        .badge-log {
            color: ${({ theme }) => theme.badge_log_text};
            background-color: ${({ theme }) => theme.badge_log};    
        }
        .badge-info {
            color: ${({ theme }) => theme.badge_info_text};
            background-color: ${({ theme }) => theme.badge_info};    
        }
        .badge-low {
            color: ${({ theme }) => theme.badge_low_text};
            background-color: ${({ theme }) => theme.badge_low};    
        }
        .badge-medium {
            color: ${({ theme }) => theme.badge_medium_text};
            background-color: ${({ theme }) => theme.badge_medium};    
        }
        .badge-high {
            color: ${({ theme }) => theme.badge_high_text};
            background-color: ${({ theme }) => theme.badge_high};    
        }




        /* SIDEBAR */


     

        // header
       
        .c-sidebar .c-sidebar-brand {
            color: ${({ theme }) => theme.sidebar_header_text};
            background: ${({ theme }) => theme.sidebar_header};
            //padding-top: 20px;
            //padding-bottom: 20px;
            padding: 15px;
            height: 52px;
            max-height: 52px;
            border-bottom: 2px solid #131313;
        }
        // footer
        .c-sidebar .c-sidebar-minimizer {
            color: ${({ theme }) => theme.sidebar_header_text};
            background: ${({ theme }) => theme.sidebar_header_text};
        }
        .c-sidebar .c-sidebar-minimizer:hover {
            color: ${({ theme }) => theme.sidebar_header_text};
            background: ${({ theme }) => theme.sidebar_header_text};
        }

        .c-sidebar-nav {
            background: ${({ theme }) => theme.sidebar_body};
            background-color: ${({ theme }) => theme.sidebar_body};
        }
        
        // navbar title
        .c-sidebar .c-sidebar-nav-title {
            background: ${({ theme }) => theme.sidebar_body};
            background-color: ${({ theme }) => theme.sidebar_body};
            color:  ${({ theme }) => theme.sidebar_body_nav_title};
            font-size: 0.7rem;
            font-weight: 700;
        }
        
        // navbar item
        .c-sidebar .c-sidebar-nav-item {
            background: ${({ theme }) => theme.sidebar_body};
            background-color: ${({ theme }) => theme.sidebar_body};
            color: #cccccc;
            height: 36px;
        }
        .c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link{
            opacity: 0.75;
            text-align: left;
            color: #fff;
            height: 100%;     
        }
        .c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link:hover {
            background: linear-gradient(to right, #73b89e, rgba(187, 255, 229, 0));
            opacity: 1;
        }
        .c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link.c-active {
            background: linear-gradient(to right, rgba(23, 191, 131, 0.6), rgba(23, 191, 131, 0.15) 61%, rgba(23, 191, 131, 0));
            opacity: 1;
        }
        .c-sidebar .c-sidebar-nav-item .c-sidebar-nav-link.c-disabled {
            opacity: 0.3;
            cursor: default;
        }
        
        // navbar droddown
        .c-sidebar .c-sidebar-nav-dropdown {
            background: ${({ theme }) => theme.sidebar_body};
            background-color: ${({ theme }) => theme.sidebar_body};
        }
        .c-sidebar .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle:hover {
            background-color: ${({ theme }) => theme.sidebar_body_hover};
        }
        
        // navbar divider
        .c-sidebar .c-sidebar-nav-divider {
            background: ${({ theme }) => theme.sidebar_body};
            background-color: ${({ theme }) => theme.sidebar_body};
        }


        // navbar minimized


        // navbar item
        .c-sidebar-minimized .c-sidebar-nav .c-sidebar-nav-item:hover {
            background-color: ${({ theme }) => theme.sidebar_body_hover};
        }
        .c-sidebar-minimized .c-sidebar-nav .c-sidebar-nav-item:hover .c-sidebar-nav-link:hover {
            background-color: ${({ theme }) => theme.sidebar_body_hover};
        }



        // dropdown item
        .c-sidebar-minimized .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle {
            background-color: ${({ theme }) => theme.sidebar_body};
        }

        .c-sidebar-minimized .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-toggle:hover {
            background-color: ${({ theme }) => theme.sidebar_body};
        }


        .c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
            background-color: ${({ theme }) => theme.sidebar_body};
        }
        

        /* FORMS */

        .form-control {
            background-color: #131313  !important;
            border: unset;
            border-radius: unset;
            border-bottom: 1px solid ${({ theme }) => theme.form_border};
            color: ${({ theme }) => theme.form_text};
        }

        .form-control:focus {
            color: ${({ theme }) => theme.form_text};
            background-color: ${({ theme }) => theme.form};
            //border-color: ${({ theme }) => theme.form_border};
            outline: unset;
            //box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.form_border};
            box-shadow: unset;
        }

        .form-control:disabled, .form-control[readonly] {
            background-color: ${({ theme }) => theme.form};
            border: unset;
            border-radius: unset;
            border-bottom: 1px  solid  white;
            opacity: 1;
        }

        .select-input {
            .css-1pahdxg-control {
                color: ${({ theme }) => theme.select_text};
                background-color: ${({ theme }) => theme.select};
                border-color: ${({ theme }) => theme.select_border};
                outline: 0;
                box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.select_border};
            }
            .css-1pahdxg-control:hover {
                border-color: ${({ theme }) => theme.select_border};
            } 
        }

        
        input[type="date"]::-webkit-calendar-picker-indicator {
            //color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url(${({ theme }) => theme.calendar}) no-repeat;
            width: 16px;
            height: 16px;
            border-width: thin;
            outline: unset;
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
            //color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url(${({ theme }) => theme.clock}) no-repeat;
            width: 16px;
            height: 16px;
            border-width: thin;
            outline: unset;
        }




        .input-group-text {
            border: unset;
            border-bottom: 1px solid;
            border-radius: unset;
            color: ${({ theme }) => theme.form_border};
            background-color: ${({ theme }) => theme.input};
            border-color: ${({ theme }) => theme.form_border};
        }








        /* SWITCHES */
        // default
        .c-switch-3d-success .c-switch-input:checked + .c-switch-slider {
            background-color: ${({ theme }) => theme.success_switch};
        }

        .c-switch-3d-danger .c-switch-input:checked + .c-switch-slider {
            background-color: ${({ theme }) => theme.danger_switch};
        }
        .c-switch-3d-info .c-switch-input:checked + .c-switch-slider {
            background-color: ${({ theme }) => theme.info_switch};
        }
        .c-switch-3d-warning .c-switch-input:checked + .c-switch-slider {
            background-color: ${({ theme }) => theme.warning_switch};
        }
    
        .c-switch-slider {
            position: relative;
            display: block;
            height: 25px !important;
            width: 38px !important;
            cursor: pointer;
            background-color: ${({ theme }) => theme.background};
            border: 1px solid ${({ theme }) => theme.background};
            transition: .15s ease-out;
            border-radius: 0.25rem;
        }

        .c-switch-slider::before {
            background-color: ${({ theme }) => theme.switch};
        }
    
        .c-switch[class*="-3d"] .c-switch-slider::before {
            top: 2px;
            left: 2px;
            width: 19px !important;
            height: 19px !important;
            border: 0;
            border-radius: 50em;
            box-shadow: 0 2px 5px rgba(0, 0, 21, 0.3);
        }
        
    
        .c-switch-input:checked ~ .c-switch-slider::before {
            transform: translateX(14px);
        }
    
    
        // custom shape
        .switches {
            margin-top: 10px;
            text-align: left;
        }
    
        .switch-title{
            margin-left: 8px;
            text-align: left;
        }
        .switch{
            padding-top: 5px;
        }

        .c-switch-input:focus ~ .c-switch-slider {
            color: #768192;
            background-color: #fff;
            border-color: #000000;
            outline: 0;
            box-shadow: unset;
        }



        /* RADIO */
        /* COMMON RADIO AND CHECKBOX STYLES  */
        
        .form-check-input {
            margin-top: 4px;
        }

        /*
        input[type=radio],
        input[type=checkbox]{
            // Hide original inputs
            visibility: hidden;
            position: absolute;
        }
        input[type=radio] + label:before,
        input[type=checkbox] + label:before{
            height:12px;
            width:12px;
            margin-right: 2px;
            content: " ";
            display:inline-block;
            vertical-align: baseline;
            border: 1px solid ${({ theme }) => theme.form_border};
        }
        input[type=radio]:checked + label:before,
        input[type=checkbox]:checked + label:before{
            background:${({ theme }) => theme.radio};
        }

        /* CUSTOM RADIO AND CHECKBOX STYLES */
        input[type=radio] + label:before{
            border-radius:50%;
        }
        input[type=checkbox] + label:before{
            border-radius:2px;
        }
        */




        
        /* TABLES */

        .table-responsive {
            overflow-x: unset;
            overflow-y: unset;
        }

        .table thead th {
            background-color: ${({ theme }) => theme.table_header};
            color: ${({ theme }) => theme.table_header_text};
        }

        .table th, .table td {
            border-top-color: ${({ theme }) => theme.table_row};
        }

        // inside table buttons
        .btn-outline-primary{
            color: ${({ theme }) => theme.table_btn_outline_text};
            background-color: ${({ theme }) => theme.table_btn_outline};
            border-color: ${({ theme }) => theme.table_btn_outline_border};
        }
        .btn-outline-primary:hover{
            color: ${({ theme }) => theme.table_btn_outline_text};
            background-color: ${({ theme }) => theme.table_btn_outline};
            border-color: ${({ theme }) => theme.table_btn_outline_border};
            opacity: 0.8;
        }
        .btn-outline-primary:focus {
            color: ${({ theme }) => theme.table_btn_outline_text};
            background-color: ${({ theme }) => theme.table_btn_outline};
            border-color: ${({ theme }) => theme.table_btn_outline_border};
            //box-shadow: 0 0 0 0.2rem rgba(#152433, 0.25);
            box-shadow: unset;
        }
        .btn-outline-primary:active {
            color: ${({ theme }) => theme.table_btn_outline_text};
            background-color: ${({ theme }) => theme.table_btn_outline};
            border-color: ${({ theme }) => theme.table_btn_outline_border};
            //box-shadow: 0 0 0 0.2rem rgba(#152433, 0.25);
            box-shadow: unset;
        }
        .btn-outline-primary:active:not(:disabled):not(.disabled):active {
            color: ${({ theme }) => theme.table_btn_outline_text};
            background-color: ${({ theme }) => theme.table_btn_outline};
            border-color: ${({ theme }) => theme.table_btn_outline_border};
            box-shadow: unset;
        }
    
    
    
        // paginator buttons
        .page-item .page-link {
            color: ${({ theme }) => theme.paginator_text};
            background-color: ${({ theme }) => theme.paginator};
            border-color: ${({ theme }) => theme.paginator};  
        }
        .page-item.active .page-link {
            color: ${({ theme }) => theme.paginator_text};
            background-color: ${({ theme }) => theme.paginator};
            border-color: ${({ theme }) => theme.paginator};
        }
        .page-item .page-link:focus {
            color: ${({ theme }) => theme.paginator_text};
            background-color: ${({ theme }) => theme.paginator};
            border-color: ${({ theme }) => theme.paginator};
            box-shadow: unset;
        }
    
    
    
    
        /* CARD */
    
        .card {
            background-color: #131313;
            border: unset;
            margin-bottom: 0px !important;
        }
        
 
        /* ALERTS */
        .alert-danger {
            color: ${({ theme }) => theme.alert_danger_text};
            background-color: ${({ theme }) => theme.alert_danger};
            border-color: ${({ theme }) => theme.alert_danger_border};
        }

        .alert-info {
            color: ${({ theme }) => theme.alert_info_text};
            background-color: ${({ theme }) => theme.alert_info};
            border-color: ${({ theme }) => theme.alert_info_border};
        }
        .alert-success {
            color: ${({ theme }) => theme.alert_success_text};
            background-color: ${({ theme }) => theme.alert_success};
            border-color: ${({ theme }) => theme.alert_success_border};
        }
     

        
        // CHARTS

        .chart {
            padding-bottom: 30px;
        }
        .word-cloud-title {
            font-size: 18px;
            text-align: center;
            padding-bottom: 10px;
        }
        .chart-title {
            font-size: 18px;
            text-align: left;
            padding-bottom: 10px;
        }
        .export-button {
            float: right;
        }
 
    
        // NOTIFICATIONS
        .clear-notifications{
            padding-left: 10px;
            cursor: pointer;
        }
        .clear-notifications:hover{
            text-decoration-line: underline;
        }
    
 
    
        // MESSAGES
        .clear-messages{
            padding-left: 10px;
            cursor: pointer;
        }
        .clear-messages:hover{
            text-decoration-line: underline;
        }
    
        .c-header .c-header-nav .c-header-nav-link:hover, .c-header .c-header-nav .c-header-nav-link:focus, .c-header .c-header-nav .c-header-nav-btn:hover, .c-header .c-header-nav .c-header-nav-btn:focus {
            color: unset;
        }



        /* MODALS */
    
        .modal-dialog {
            position: relative;
            top: 40%;
            transform: translateY(-40%) !important;
            width: 100%;
            text-align: left;
        }

        .modal-content {
            border: unset;
            background-color: ${({ theme }) => theme.modal_background}
        }
    
        .modal-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            //padding: 1rem 1rem;
            border-bottom: 1px solid;
            border-top-left-radius: calc(0.3rem - 1px);
            border-top-right-radius: calc(0.3rem - 1px);
            border-color: ${({ theme }) => theme.modal_header_border};
            padding-left: 10px;
            padding-right: 10px;
            margin-left: 10px;
            margin-right: 10px;
        }
    
        .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: 1rem;
            margin-top: 16px;
        }
    
        .modal-footer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: 0.75rem;
            border-top: 1px solid;
            border-bottom-right-radius: calc(0.3rem - 1px);
            border-bottom-left-radius: calc(0.3rem - 1px);
            border-color: ${({ theme }) => theme.modal_footer_border};
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 10px;
            margin-right: 10px;
        }
        
        

        .modal-button {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-button:active{
            background-color: ${({ theme }) => theme.button};
            outline: unset;
        }
        .modal-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-button:not(:disabled):not(.disabled):active:focus, .show > .modal-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    
        .modal-button:disabled {
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
        }
        .modal-button:focus{
            outline: unset;
        }





        .modal-body-button {
            margin-right: 10px;
            //width: 100px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-body-button:active{
            background-color: ${({ theme }) => theme.button};
            outline: unset;
        }
        .modal-body-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-body-button:not(:disabled):not(.disabled):active:focus, .show > .modal-body-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    
        .modal-body-button:disabled {
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
        }
        .modal-body-button:focus{
            outline: unset;
        }
 

        .modal-success-button {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_create};
            border:  1px solid ${({ theme }) => theme.btn_create_border};
            color: ${({ theme }) => theme.btn_create_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-success-button:active{
            background-color: ${({ theme }) => theme.btn_create};
            outline: unset;
        }
        .modal-success-button:hover{
            border-color: ${({ theme }) => theme.btn_create_hover};
            color: ${({ theme }) => theme.btn_create_hover};
        }
        .modal-success-button:not(:disabled):not(.disabled):active:focus, .show > .modal-success-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    
        .modal-success-button:disabled {
            border:  1px solid ${({ theme }) => theme.btn_create_border};
            color: ${({ theme }) => theme.btn_create_text};
            opacity: 0.20;
        }
        .modal-success-button:focus{
            outline: unset;
        }
        
        
    
    
        .modal-delete-button{
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_danger};
            border:  1px solid ${({ theme }) => theme.btn_danger_border};
            color: ${({ theme }) => theme.btn_danger_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-delete-button:active{
            background-color: ${({ theme }) => theme.btn_danger}; 
            outline: unset;
        }
        .modal-delete-button:hover{
            border-color: ${({ theme }) => theme.btn_danger_hover};
            color: ${({ theme }) => theme.btn_danger_hover};
        }
        .modal-delete-button:not(:disabled):not(.disabled):active:focus, .show > .modal-delete-button.dropdown-toggle:focus {
            box-shadow: unset;
        }    
    
        .modal-delete-button:disabled {
            border:  1px solid ${({ theme }) => theme.btn_danger_border};
            color: ${({ theme }) => theme.btn_danger_text};
            opacity: 0.20;
        }
        .modal-delete-button:focus{
            outline: unset;
        }




        .update-password-button{
            width: 140px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_light};
            border:  1px solid ${({ theme }) => theme.btn_light_border};
            color: ${({ theme }) => theme.btn_light_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .update-password-button:active{
            background-color: ${({ theme }) => theme.btn_light}; 
            outline: unset;
        }
        .update-password-button:hover{
            border-color: ${({ theme }) => theme.btn_light_hover};
            color: ${({ theme }) => theme.btn_light_hover};
        }
        .update-password-button:not(:disabled):not(.disabled):active:focus, .show > .update-password-button.dropdown-toggle:focus {
            box-shadow: unset;
        }    
    
        .update-password-button:disabled {
            border:  1px solid ${({ theme }) => theme.btn_light_border};
            color: ${({ theme }) => theme.btn_light_text};
            opacity: 0.20;
        }
        .update-password-button:focus{
            outline: unset;
        }





        .margin-top-10{
            margin-top: 10px;
        }
        .margin-top-16{
            margin-top: 16px;
        }


        /*
        .modal-button {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-button:active{
            background-color: ${({ theme }) => theme.button};
            outline: unset;
        }
        .modal-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-button:not(:disabled):not(.disabled):active:focus, .show > .modal-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    
        .modal-button:disabled {
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
        }
        .modal-button:focus{
            outline: unset;
        }

        */




        .modal-cancel-button{
            //width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .modal-cancel-button:active{
            background-color: ${({ theme }) => theme.button};    
        }
        .modal-cancel-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-cancel-button:not(:disabled):not(.disabled):active:focus, .show > .modal-cancel-button.dropdown-toggle:focus {
            box-shadow: unset;
        }   



        .modal-form-buttons {
            float: right;
            padding-right: 0px;
            margin-right: 0px;
            margin-top: 10px;
        }

        .close:focus{
            outline: unset;
        }
        .modal-error-message {
            margin: 0 auto;
            color: red;
        }


        /*
        *
        * Admin UI
        *  
       */
       

        /*
        MODULES
        */

        /* REGISTRATION SIGNIN */
        
        .public-bg{
            min-width: 100vw;
            min-height: 100vh;
            position: fixed;
            video{
                position: fixed;
                object-fit: fill;
                top: 0; right: 0; bottom: 0; left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.9;
            }
        }             
        .registration-input{
            background-color: unset !important;
            width: 100%;
            color: #fff;
        }
        .registration-notvalid-input{
            background-color: unset !important;
            width: 100%;
            color: ${({ theme }) => `${theme.form_field_warning} !important`};
            border-bottom: ${({ theme }) =>
              `1px solid ${theme.form_field_warning} !important`};
            &:focus{
                color: ${({ theme }) =>
                  `${theme.form_field_warning} !important`};
                border-bottom: ${({ theme }) =>
                  `1px solid ${theme.form_field_warning} !important`};
            }
        }

        /* LOGIN */
        .login-page-wrapper .container{
            position: absolute;
            top: 300px;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            min-height: 50%;
            min-width: 50%;
        }
        .bg-primary {
            background-color: #152433 !important;
        }
        .card-group{
            opacity: 0.8;
        }
    
        .login-page-wrapper {
            width: 100px;
        }
        

        .login-logo {
            //margin: 0 auto;
            height: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .login-logo-image {
            max-height: 240px;
            max-width: 250px;

            vertical-align: middle;
            display: inline-block;
        }


        .login-page-wrapper video {
            position: fixed;
            object-fit: fill;
            top: 0; right: 0; bottom: 0; left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.9;
        }



        .login-title {
            color: ${({ theme }) => theme.text};
        }

        //login mute button
        .mute-button:hover {
            background-color: rgba(255, 255, 255, 0.7);
        }
        .mute-button-login{
            position: absolute;
            bottom: 15px;
            right: 15px;
            color: rgba(255, 255, 255, 0.8);
            background-color: rgba(255, 255, 255, 0.3);
            border: unset;
            .mute-button-image {
                width: 16px;
            }
        }


        
        .register-event-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
    
        .register-event-button:hover{
            border-color: ${({ theme }) => theme.button_hover};
            color: ${({ theme }) => theme.button_hover};
        }
    
        .register-event-button:disabled {
            border-color: ${({ theme }) => theme.button_hover};
            color: ${({ theme }) => theme.button_hover};
            opacity: 0.20;
        }
    
        .register-event-button:focus {
            outline: unset;
        } 
        



        .login-button{
            width: 120px;
            height: 28px;
            background-color: #131313;
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            //padding-top: 6px;
            margin-left: 8px;
        }
    
        .login-button:active{
            background-color: ${({ theme }) => theme.button};
        }
        .login-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .login-button:not(:disabled):not(.disabled):active:focus, .show > .login-button.dropdown-toggle:focus {
            box-shadow: unset;
        }


        .login-buttons {
            display: flex;
            align-items: center;
        }

        .reset-password-button {
            float: right;
            margin-right: 8px;
            color: ${({ theme }) => theme.text};
        }
        .reset-password-button:hover {
            color: ${({ theme }) => theme.text_hover};
        }

        .login-password-input {
            width: 100%;
        }

        .login-password-show-button {
            font-size: 10px !important;
            margin-top: -10px !important;
            padding: 2px 5px !important;
            background: #131313 !important;
            color: ${({ theme }) => theme.button_text} !important;
        }

        


        /* EVENT REGISTRATION */
        .event-registration-hline {
            margin-top: 6px;
            margin-bottom: 6px;
            border-bottom: 1px solid white;
            border-image: 
            linear-gradient(
              to right, 
              rgba(0,0,0, 0),
              rgba(255,255,255, 1.0),
              rgba(0,0,0, 0) 
            ) 1;
        }

        .event-registration-info-hline {
            margin-top: 6px;
            margin-bottom: 6px;
            border-bottom: 1px solid white;
            border-image: 
            linear-gradient(
              to right, 
              rgba(0,0,0, 0),
              rgba(255,255,255, 0.2),
              rgba(0,0,0, 0) 
            ) 1;
        }

        .register-info {
            width: 100%;
            text-align: center;
            padding-top:  20px;
        }
        .span-click-text {
            cursor: pointer;
            font-size: 10px;
        }
        .margin-left-10 {
            margin-left: 10px;
        }

        .event-info-text {
            color: ${({ theme }) => theme.text};
        }

        



        
        

        

        /* EVENTS */

        // search
        .table-buttons {
            padding-right: 5px;
            margin-bottom: 10px;
            .form-control:focus {
                color: unset;
                background-color: unset;
                border-color: unset;
                outline: 0;
                box-shadow: unset;
            }
    
        }
    
        .search-box {
            border-bottom: 1px solid ${({ theme }) => theme.input_border};
            //border-radius: 0.3em;
            height: 28px;
        }
    
        .search-input {
            height: 26px;
    
            .form-control:focus {
                box-shadow: unset;
            }

            border: unset;
            background-color: #131313;
            color: ${({ theme }) => theme.text};
    
        }
    
        .search-input-icon {
            margin-top: 2px;
            margin-right: 7px;
            height: 24px;
            width: 24px;
            background-image: url(${search_icon});
        }
    
        .clear-icon {
            margin-top: 2px;
            margin-right: 7px;
            height: 24px;
            width: 24px;
            background-image: url(${clear_icon});
        }

        
        
        //table
        .events-table {
            border: unset;
            background-color: transparent;
            
            .table {
                color: ${({ theme }) => theme.table_text};
            }
            .table th {
                border: unset;
            }
    
            .table thead th {
                background-color: ${({ theme }) => theme.table_header};
                color: ${({ theme }) => theme.table_header_text};
            }

            .table th:hover {
                background-color: ${({ theme }) => theme.table_header_hover};
            }
            
            .table th:nth-child(1){
            border-radius:5px 0px 0px 5px;
            }
    
            .table th:nth-child(5){
            border-radius:0px 5px 5px 0px;
            }
            .table th:nth-child(5):hover{
                background-color: ${({ theme }) => theme.table_header};
            }
    
            .table tr:first-child td{
                border: unset;
            }
    
            .table tr:hover {
                padding: 0.5rem;
                padding-left: 1px;
            }
    
            .table-hover tbody tr:hover {
                background-color: ${({ theme }) => theme.table_row_hover};
                color: ${({ theme }) => theme.table_text};
            }
    
            .table td {
                padding: 0.5rem;
            }
    
            .manage-buttons {
                width: 100%;
                padding-right: 10px;
            }
    
            
            
            .stop-button{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) => theme.table_stop_button};
                border:  1px solid ${({ theme }) =>
                  theme.table_stop_button_border};
                color: ${({ theme }) => theme.table_stop_button_text};
                border-radius: 0.6em;
                font-weight: 500;
                float: left;
                font-size: 11px;
                padding-top: 6px;
            }
        
            .stop-button:active{
                background-color: ${({ theme }) => theme.table_stop_button};   
            }
            .stop-button:hover{
                border-color: ${({ theme }) =>
                  theme.table_stop_button_border_hover};
                color: ${({ theme }) => theme.table_stop_button_text_hover};
            }
            .stop-button:not(:disabled):not(.disabled):active:focus, .show > .stop-button.dropdown-toggle:focus {
                box-shadow: unset;
            }
            
            
            
            .continue-button{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) => theme.table_continue_button};
                border:  1px solid ${({ theme }) =>
                  theme.table_continue_button_border};
                color: ${({ theme }) => theme.table_continue_button_text};
                border-radius: 0.6em;
                font-weight: 500;
                float: left;
                font-size: 11px;
                padding-top: 6px;
            }
    
            .continue-button:active{
                background-color: ${({ theme }) =>
                  theme.table_continue_button};;   
            }
            .continue-button:hover{
                border-color: ${({ theme }) =>
                  theme.table_continue_button_border_hover};
                color: ${({ theme }) => theme.table_continue_button_text_hover};
            }
            .continue-button:not(:disabled):not(.disabled):active:focus, .show > .continue-button.dropdown-toggle:focus {
                box-shadow: unset;
            }
           

    
            .terminate-button{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) =>
                  theme.table_terminate_button};
                border:  1px solid ${({ theme }) =>
                  theme.table_terminate_button_border};
                color: ${({ theme }) => theme.table_terminate_button_text};
                border-radius: 0.6em;
                font-weight: 500;
                font-size: 11px;
                padding-top: 6px;
            }
    
            .terminate-button:active{
                background-color: ${({ theme }) =>
                  theme.table_terminate_button};   
            }
            .terminate-button:hover{
                border-color: ${({ theme }) =>
                  theme.table_terminate_button_border_hover};
                color: ${({ theme }) =>
                  theme.table_terminate_button_text_hover};
            }
            .terminate-button:not(:disabled):not(.disabled):active:focus, .show > .terminate-button.dropdown-toggle:focus {
                box-shadow: unset;
            }
     
    
            .add-event-button{
                width: 120px;
                height: 28px;
                background-color: ${({ theme }) => theme.button};
                border:  1px solid ${({ theme }) => theme.button_border};
                color: ${({ theme }) => theme.button_text};
                border-radius: 0.6em;
                font-weight: 500;
                float: left;
                font-size: 11px;
                padding-top: 6px;
            }
        
            .add-event-button:active{
                background-color: ${({ theme }) => theme.button};
            }
            .add-event-button:hover{
                //background-color: ${({ theme }) => theme.button_hover};
                border-color: ${({ theme }) => theme.button_border_hover};
                color: ${({ theme }) => theme.button_text_hover};
            }
            .add-event-button:not(:disabled):not(.disabled):active:focus, .show > .add-event-button.dropdown-toggle:focus {
                box-shadow: unset;
            }




            .event-button {
                text-decoration:underline !important;
            }
            .event-button:hover {                
            }

            .nobutton {
                cursor: unset;
            }

 
        }
        
     
        //fields
        .event-status {
            text-align: center;
            //vertical-align: middle;
        }
        .event-status-badge {
            /*
            // .badge (default)
            display: inline-block;
            padding: 0.25em 0.4em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            border-radius: 0.25rem;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            */
    
            height: 24px;
            width: 100px;
            font-size: 90%;
            font-weight: unset;
            line-height: unset;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            border-radius: 0.25rem;
            background-color: white;
            border: 1px solid;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    
    
        .progress-text { 
            font-size: 10px;
            padding-top: 0px;
        }
    
        .progress { 
            margin-bottom: unset;
            margin-top: unset;
        }
    
        .start-time {
            vertical-align: middle;
        }    
        .campaign {
            vertical-align: middle;
        }
        .duration {
            vertical-align: middle;
        }
        .place {
            vertical-align: middle;
            text-align: center;
        }
        .points {
            vertical-align: middle;
            text-align: center;
        }
    
        /* EVENTSNEW */
    
        
        .events-overview-header-button:hover{
            opacity: 0.7;
        }
        .events-overview-header-button:disabled{
            opacity: 0.35;
            cursor: unset !important;
        }
        .events-overview-header-button:not(:disabled) {
            opacity: 0.7;
        }
        .events-campaign-statistics-label {
            font-family: 'Roboto', sans-serif;
            font-size: 14px; 
            color: rgba(255, 255, 255, 0.5);
            line-height: normal;
        }
        .events-campaign-statistics-data {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
            word-break: break-all;
        }
        .medium-font{
            font-size: 14px;
        }
        .small-font{
            font-size: 12px;
        }
        .events-list-title {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 550;
            color: rgba(255, 255, 255, 0.8);
        }
        .sortby_button_card {
            width: 106px;
            height: 34px;
            background-color: #4e4d4d;
            border-radius: 8px;  
        }
        .sortby_button {
            height: 26px;
            border-radius: 8px;
            color: rgba(255, 255, 255, 0.65);
            text-align: center;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: 550;
            background-color: rgba(0,0,0,0);
            border: none;  
            outline: none;
        }
        .place_button {
            width: 48;       
        }
        .activity_button {
            width: 58;
        }
        .sortby_active {
            background-color: rgba(23, 191, 131, 0.7);
            color: #fff;
            outline: none;
        }
        .general_event_card {
            height: auto;
            //min-height: 144px;
            width: auto;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px; 
            border-right: 2px solid #393939;
            outline-style: unset;
            outline-width: unset;
            outline-color: none;
            background-color: #2a2a2a;   
            //z-index: -1;
        }
        .target {
            border-radius: 0px;       
        }
        .target-empty {
            padding: 0px;
        }
       
        .users {
            min-width: 96px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-right: none;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .col_margins {
            margin: 16px 0px 16px 16px; 
        }
        
        .card_text_layout {
            margin: 0px 0px 0px 0px; 
            word-break: "break-all";
            line-height: normal;
        }
        .card_big_title {
            font-family: 'Roboto',sans-serif;
            font-size: 24px;
            font-weight: 550;
            color: #fff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: auto;
            max-width: 150px
        }
        .team_place_num {
            font-family: 'Roboto', sans-serif;
            font-size: 46px;
            font-weight: bold;
            color: #49bd95;
        }
        .place-title {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
        }
        .target-title {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
        }
      
       
       
       
        .events-team-btn {
            background-color: rgba(0,0,0,0);
            border: none;  
            outline: none;
        }
        
        .events-team-btn:active {
            opacity: 0.5;
            border: none;  
            outline: none;
        }
        .events-redeploy-btn {
            background-color: rgba(0,0,0,0);
            outline: none;
            width: 26px;
            height: 28px;
            border: solid 2px rgba(255, 255, 255, 0.5);
            border-radius: 8px;
            margin-left: 8px;
            align-items: center;
            justify-content: center;
            display: flex;
        }
        .events-redeploy-btn:disabled {
            
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
            
        }
    
        
        .target-row-grid {
            width: 100%;
            display: grid; 
            grid-template-columns: repeat(auto-fit,minmax(290px,1fr));
            margin-bottom: -2px;
        }
        .target-row-grid-empty {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
       
        .player-content {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
        }
        .player-content-green {
            color: rgba(23, 191, 131, 0.7);
        }
        .player-badge-score {
            font-family: 'Agency FB Bold', arial;
            font-size: 24px; 
            color: rgba(255, 255, 255, 0.5);
            z-index: 3;
        }
        
        
      
       
 
      
        .log-display {
            width: 90%;
        }
         
        .events-empty-stats {
            color: rgba(255, 255, 255, 0.25);
        }
        .events-empty-2 {
            color: rgba(255, 255, 255, 0.5);
        }
      
        .events-noteam-btn {
            .continue-button {
                font-size: 12px;
                color: rgba(255,255,255,0.8);
                text-decoration: underline;
                margin-left: 0 !important;
                background-color: rgb(42,42,42);
                padding: 0;
            }
        }
      
        .events-avatar {
            height: 30px; 
            width: 30px;
            border-radius: 50%;
        }
       
       
     
        /* CAMPAIGNS */


        .campaigns-filters-menu {
            position: sticky;
            top: 140px;
            //overflow: hidden;
            padding-left: 24px;
            //height: 800px;
            //width: 100%;
        }
    
        .campaigns-menu {
        }

        .campaigns-campaign-wrapper{
            width: 100%;
            height: 100%;
            padding-bottom: 16px;
        }
        
        .campaigns-campaign-container-red {
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${red_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
        }
    
        .campaigns-campaign-container-red:hover {
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${red_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transform: scale(1.025);
        }
    
    
        .campaigns-campaign-container-blue {
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${blue_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
        }
    
        .campaigns-campaign-container-blue:hover {
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${blue_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transform: scale(1.025);
        }
    
        
        .campaigns-campaign-grid {
            padding-top: 12px;
            padding-left: 32px;
            padding-bottom: 12px;
            text-align: center;
            color: ${({ theme }) => theme.campaigns_grid_text};
            background-color: ${({ theme }) => theme.campaigns_grid};
            //opacity: 0.6;
        }
        .campaigns-campaign-title {
            color: ${({ theme }) => theme.campaigns_grid_text};
            font-size: 18px;
            font-weight: bold;
        }
        .campaigns-campaign-subtitle {
            color: ${({ theme }) => theme.campaigns_grid_text};
            font-size: 16px;
        }
        .campaigns-campaign-hline {
            border-bottom: 1px solid ${({ theme }) => theme.campaigns_hline};
            margin-top: 12px;
            margin-right: 28px;
            margin-bottom: 12px;
        }
    
        .campaigns-campaign-info {
            color: ${({ theme }) => theme.campaigns_info_text};
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            margin: unset;
            padding: unset;
        }
        .campaigns-campaign-info-vline {
            color: ${({ theme }) => theme.campaigns_info_vline_text};
            font-size: 12px;
            font-weight: bold;
            padding-right:20px;
        }
        .campaigns-campaign-grid-vline {
            border-left-style: solid;
            border-left: 2px solid ${({ theme }) =>
              theme.campaigns_grid_vline_border};
            ${({ theme }) => theme.campaigns_campaign_grid_vline_style};
        }
        .campaigns-campaign-section-title {
            color: ${({ theme }) => theme.campaigns_section_title_text};
            font-size: 12px;
            font-weight: bold;
            padding-top: 5px;
        }
        .campaigns-campaign-topics {
            color: ${({ theme }) => theme.campaigns_topics_text};
            font-size: 14px;
            padding-top: 8px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            line-height: 30px;
            padding: 5px;
            position: relative;
        }  
    
        .campaigns-campaign-topics span {
            position: absolute;
            white-space: nowrap;
            transform: translateX(0);
            transition: 15s;
        }
    
        .campaigns-campaign-topics:hover span {
            transform: translateX(calc(100px - 100%));
        }
    
        .campaigns-campaign-industries {
            color: ${({ theme }) => theme.campaigns_industries_text};
            font-size: 14px;
            padding-top: 8px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            line-height: 30px;
            padding: 5px;
            position: relative;
        } 
        .campaigns-campaign-industries span {
            position: absolute;
            white-space: nowrap;
            transform: translateX(0);
            transition: 15s;
        }
    
        .campaigns-campaign-industries:hover span {
            transform: translateX(calc(100px - 100%));
        }
    
        .campaigns-campaign-button-grid {
            padding: 40px 32px 32px 32px;
        } 
    
        /* Campaign Filters */
        .campaigns-campaign-filters-container {
            //padding-top: 16px;
            margin-top: 16px;
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;
            border-right: 1px solid ${({ theme }) =>
              theme.campaigns_filter_container_border};
    
            /*
            border-right-width: 1px;
            border-right-style: solid;
            border-image: 
            linear-gradient(
                to bottom, 
                rgba(0,0,0, 0.0),
                rgba(0,0,0, 0.3),
                rgba(0,0,0, 0.0) 
            ) 1 100%;
            */
    
    
        }
    
    
        .campaigns-campaign-filter-header {
            width: 100%;
            //left: 0px;
            //position: absolute;
    
            .col-8 {
                padding-left: 0px;
                margin-left: 0px;
            }
    
            .campaigns-campaign-filter-header-title {
                font-size: 18px;
                font-weight: 600;
                font-family: 'Roboto', sans-serif;
                color: ${({ theme }) => theme.text};
            } 
    
            .buttons {
                position: absolute;
                right: 0;
                top: 0;
                padding-top: 8px;
                margin-right: 0px;
                width: 100%;
            }
    
            .reset-button {
                color: ${({ theme }) => theme.campaigns_filter_reset_button};
                font-size: 12px;
            }
    
            .reset-button:hover {
                color: ${({ theme }) =>
                  theme.campaigns_filter_reset_button_hover};
            }
    
        }
      
        .campaigns-campaign-filter-header-hline {
            border-top: 1px solid ${({ theme }) => theme.campaigns_hline};
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    
        
        .campaigns-campaign-filter {
            width: 100%;
            padding-bottom: 10px;
            
            
            .form-control:focus {
                color: unset;
                background-color: unset;
                border-color: unset;
                outline: 0;
                box-shadow: unset;
            }
            
    
        }
        .campaigns-campaign-filter-title {
            padding-left: 16px;
            padding-bottom: 8px;
            font-size: 15px;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
            color: ${({ theme }) => theme.text};
        }



        .campaigns-campaign-search-box {
            border-bottom: 1px solid ${({ theme }) => theme.input_border};
            //border-radius: 0.3em;
        }
    
        .campaigns-campaign-search-input, .campaigns-campaign-search-input:disabled {
            border: unset;
            background-color: ${({ theme }) => theme.input};
            color: ${({ theme }) => theme.text};
        }
    
        .campaigns-campaign-search-icon {
            margin-top: 7px;
            margin-right: 7px;
            height: 24px;
            width: 24px;
            background-image: url(${search_icon});
        }
    
        .campaigns-campaign-clear-icon {
            margin-top: 7px;
            margin-right: 7px;
            height: 24px;
            width: 24px;
            background-image: url(${clear_icon});
        }


        .type-filter {
            margin-top: 10px;
            padding-bottom: unset;
        }
    
        .campaigns-campaign-filter-type-title {
            width: 200px;
            color: ${({ theme }) => theme.text};
        }
        .campaigns-campaign-filter-select {
            width: 100%;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            font-style: italic;
            color: ${({ theme }) => theme.select_text};
        }

        .css-1rhbuit-multiValue {
            background-color: ${({ theme }) => theme.select_item_badge};
            color: ${({ theme }) => theme.select_item_badge_text};
        }
        .css-yk16xz-control {
            background-color: ${({ theme }) => theme.select};
            color: ${({ theme }) => theme.select_border};
        }

        .css-yk16xz-control:focus {
            outline: unset;
        }

        .css-g1d714-ValueContainer {
            background-color: ${({ theme }) => theme.select};
            color: ${({ theme }) => theme.select_text};
        }

        .css-g1d714-ValueContainer:focus {
            outline: unset;
        }

        .css-26l3qy-menu, .css-1w9j89e-menu {
            background-color: ${({ theme }) => theme.select};            

            //height: 400px;
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px;
                //border: 2px solid white; /* should match background, can't be transparent */
                background-color: rgba(white, 0.2);
                
            }

        }

        .css-4ljt47-MenuList, .css-wzlap7-MenuList {
            //height: 400px;
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px;
                //border: 2px solid white; /* should match background, can't be transparent */
                background-color: rgba(white, 0.2);
                
            }
        }




        // select menu item

        .css-1fhf3k1-control {
            background-color: ${({ theme }) => theme.select};
            color: ${({ theme }) => theme.select_text_disabled};
        }

        /*
        .css-g1d714-ValueContainer {
            background-color: ${({ theme }) => theme.select_disabled};
            color: ${({ theme }) => theme.select_text_disabled};
        }
        */

        .css-yt9ioa-option{
            background-color: ${({ theme }) => theme.select};
            color: ${({ theme }) => theme.select_text};
        }
        .css-yt9ioa-option .active{
            background-color: ${({ theme }) => theme.select};
            color: ${({ theme }) => theme.select_text};
        }

        // select menu item hover
        .css-1n7v3ny-option {
            background-color: ${({ theme }) => theme.select_item_hover};
        }
        .css-1n7v3ny-option:active {
            background-color: ${({ theme }) => theme.select_item_hover};
            color: ${({ theme }) => theme.select_text};
        }

        // select menu item selected
        .css-9gakcf-option {
            background-color: ${({ theme }) => theme.select_item_selected};
        }
        .css-9gakcf-option .active {
            background-color: #FFFFFF;
        }

        .campaigns-campaign-filter-hline {
            border-top: 1px solid rgba(0,0,0, 0.1);
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }




        // slider

        .horizontal-slider {
            width: 100%;
            max-width: 100%;
            height: 28px;
            //border: 1px solid grey;
            border: unset;
            margin-right: 0px;
            margin-left: 0px;
            
        }
    
        .example-thumb {
            font-size: 0.9em;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(0, 0, 21, 0.3);
            background-color: ${({ theme }) => theme.switch};
        }
    
        .example-thumb.active {
            //background-color: white;
        }
    
        .example-thumb:focus {
            outline: unset;
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            -moz-box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
        }
    
        .example-thumb:hover {
            //box-shadow: unset;
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            -moz-box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
        }
    
        .example-track {
            position: relative;
            background: #F5F5F5;
            border-top-left-radius: 50em;
            border-bottom-left-radius: 50em;
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            -moz-box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);   
            margin-left: 10px; 
            margin-right: 10px;  
        }
    
        .example-track.example-track-1 {
            background: ${({ theme }) => theme.slider_range};
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            -moz-box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
        }
    
        .example-track.example-track-2 {
            background: #F5F5F5;
            border-top-right-radius: 50em;
            border-bottom-right-radius: 50em;
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            -moz-box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
        }
    
        .horizontal-slider .example-track {
            top: 16px;
            height: 5px;
        }
    
        .horizontal-slider .example-thumb {
            top: 5px;
            width: 25px;
            height: 25px;
            line-height: 28px;
            border-radius: 50em;
        }
    
        .vertical-slider .example-thumb {
            left: 1px;
            width: 48px;
            line-height: 40px;
            height: 50px;
        }
    
        .vertical-slider .example-track {
            left: 20px;
            width: 10px;
            border: unset;
        }        



        .campaigns-button {
            background-color: ${({ theme }) => theme.campaigns_button};
            color: ${({ theme }) => theme.campaigns_button_text};
            border: 1px solid ${({ theme }) => theme.campaigns_button_border};
            height: 35px;
        }

        .campaigns-button:hover {
            color: ${({ theme }) => theme.campaigns_button_text_hover};
            border: 1px solid ${({ theme }) =>
              theme.campaigns_button_border_hover};
              height: 35px;
        }













        /* CAMPAIGN */

        .campaign-wrapper-red{
            background-color: ${({ theme }) => theme.campaigns_grid};
            width: 100%;
            height: 100%;
            padding-top: 16px;
            margin-bottom: 16px;
            padding-left: 8px;
            border-left: 12px solid transparent;
            border-image: url(${red_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        }
        .campaign-wrapper-blue{
            background-color: ${({ theme }) => theme.campaigns_grid};
            width: 100%;
            height: 100%;
            padding-top: 16px;
            margin-bottom: 16px;
            padding-left: 8px;
            border-left: 12px solid transparent;
            border-image: url(${blue_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        }    
    
        .campaign-container {
            background-color: ${({ theme }) => theme.campaigns_grid};
            margin: 0px;
        }
    
        .campaign-info-hline {
            border-bottom: 1px solid ${({ theme }) => theme.campaign_hline};
            margin: 16px;
        }
    
        .campaign-info-container {
            margin: 0px;
            padding-top: 16px;
            //width: 99%;        
            //box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        }
        
        

        /* tabs */

        .red {
            .nav-tabs {
                border-bottom-color: ${({ theme }) => theme.red_tabs_border};
            }

            .nav-tabs .nav-link {
                background-color: ${({ theme }) => theme.red_tabs};
                color: ${({ theme }) => theme.red_tabs_text};
                border-bottom-color: ${({ theme }) => theme.red_tabs_border};
                width: 100%;
                height: 36px;
                text-align: center;     
                /* Only round the top corners */
                -webkit-border-top-left-radius: 15px;
                -webkit-border-top-right-radius: 15px;
                -moz-border-radius-topleft: 15px;
                -moz-border-radius-topright: 15px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;   
            }


            .nav-tabs .nav-link.active {
                background-color: #131313;
                color: ${({ theme }) => theme.red_tabs_active_text};
                border: 1px solid ${({ theme }) => theme.red_tabs_border} ;
                border-bottom-color: ${({ theme }) =>
                  theme.red_tabs_border_bottom};        
            }

            .nav-tabs .nav-link.active:hover {
                border-color: ${({ theme }) => theme.red_tabs_border};
                border-bottom-color: ${({ theme }) =>
                  theme.red_tabs_border_bottom};
            }
            
            .nav-tabs .nav-link:hover {
                border-color: ${({ theme }) => theme.red_tabs_border_hover};
                border-bottom-color: ${({ theme }) => theme.red_tabs_border};
            }
        
            .nav-tabs .nav-item:first-child {
                margin-left: 10px;
            }
        
            .nav-tabs .nav-item {
                //border-color: red;
            }


        }


    
    
        .blue {
            .nav-tabs {
                border-bottom-color: ${({ theme }) => theme.blue_tabs_border};
            }

            .nav-tabs .nav-link {
                background-color: ${({ theme }) => theme.blue_tabs};
                color: ${({ theme }) => theme.blue_tabs_text};
                border-bottom-color: ${({ theme }) => theme.blue_tabs_border};
                width: 100%;
                height: 36px;
                text-align: center;     
                /* Only round the top corners */
                -webkit-border-top-left-radius: 15px;
                -webkit-border-top-right-radius: 15px;
                -moz-border-radius-topleft: 15px;
                -moz-border-radius-topright: 15px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;   
                //border-color: red;
            }


            .nav-tabs .nav-link.active {
                background-color: #131313;
                color: ${({ theme }) => theme.blue_tabs_active_text};
                border: 1px solid ${({ theme }) => theme.blue_tabs_border} ;
                border-bottom-color: ${({ theme }) =>
                  theme.blue_tabs_border_bottom};        
            }


            .nav-tabs .nav-link.active:hover {
                border-color: ${({ theme }) => theme.blue_tabs_border};
                border-bottom-color: ${({ theme }) =>
                  theme.blue_tabs_border_bottom};
            }
            
            .nav-tabs .nav-link:hover {
                border-color: ${({ theme }) => theme.blue_tabs_border_hover};
                border-bottom-color: ${({ theme }) => theme.blue_tabs_border};
            }

        
            .nav-tabs .nav-item:first-child {
                margin-left: 10px;
            }
        
            .nav-tabs .nav-item {
                //border-color: red;
            }


        }
        
        


        .default {
            .nav-tabs {
                border-bottom-color: ${({ theme }) =>
                  theme.default_tabs_border};
            }

            .nav-tabs .nav-link {
                background-color: ${({ theme }) => theme.default_tabs};
                color: ${({ theme }) => theme.default_tabs_text};
                border-bottom-color: ${({ theme }) =>
                  theme.default_tabs_border};
                width: 100%;
                height: 36px;
                text-align: center;     
                /* Only round the top corners */
                -webkit-border-top-left-radius: 15px;
                -webkit-border-top-right-radius: 15px;
                -moz-border-radius-topleft: 15px;
                -moz-border-radius-topright: 15px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;   
                //border-color: red;
            }


            .nav-tabs .nav-link.active {
                background-color: #131313;
                color: ${({ theme }) => theme.default_tabs_active_text};
                border: 1px solid ${({ theme }) => theme.default_tabs_border} ;
                border-bottom-color: #131313;     
            }

            .nav-tabs .nav-link.active:hover {
                border-color: ${({ theme }) => theme.default_tabs_border};
                border-bottom-color:#131313;
            }
            
            .nav-tabs .nav-link:hover {
                border-color: ${({ theme }) => theme.default_tabs_border_hover};
                border-bottom-color:#131313;
            }
        
            .nav-tabs .nav-item:first-child {
                margin-left: 10px;
            }
        
            .nav-tabs .nav-item {
                //border-color: red;
            }


        }
    
    
    
        /* target tab */
        .target-wrapper {
            margin: 10px;
            padding-top: 16px;
            margin-left: 0px;
            overflow-y: unset;
            overflow-x: hidden;
            height: calc(75vh - 91px);
        }
        .target-title {
            color: ${({ theme }) => theme.campaign_title};
            font-size: 18px;
        }
        .target-summary {
            padding-top: 24px;
        }
        .target-image {
            width: 100%;
            height: 200px;
            box-shadow: 0 -0.5px 4px 0 rgba(${({ theme }) =>
              theme.campaign_target_image_shadow}, 0.35), 0 1px 4px 0 rgba(${({
  theme,
}) => theme.campaign_target_image_shadow}, 0.35);
            border-radius: 0.4em;
        }
        .target-hline {
            margin-top: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid transparent;
            border-image: 
            linear-gradient(
              to right, 
              rgba(255,255,255, 0),
              rgba(0,0,0, 0.2),
              rgba(255,255,255, 0) 
            ) 1;
        }
    
        /* mission */
        .mission-wrapper {
            margin: 10px;
            padding-top: 16px;   
            margin-left: 0px;
            //overflow-y: scroll;
            overflow-y: unset;
        }
        .mission-title {
            color: ${({ theme }) => theme.campaign_title};
            font-size: 16px;
            padding-bottom: 10px;
        }
        .mission-subtitle {
            color: ${({ theme }) => theme.campaign_subtitle};
            font-size: 13px;
            padding-right: 5px;
        }
        .mission-text {
            padding-bottom: 20px;
        }
        .mission-image {
            width: 100%;
        }
        .mission-hline {
            border-bottom: 1px solid ${({ theme }) =>
              theme.campaign_mission_hline};
            margin-top: 12px;
            margin-bottom: 12px;
        }
    
        .right-border{
            border-right: 1px solid ${({ theme }) =>
              theme.campaign_mission_border};
            border-image: 
            linear-gradient(
              to bottom, 
              rgba(255,255,255, 0) ,
              rgba(0,0,0, 0.2),
              rgba(255,255,255, 0) 
            ) 1 100%;
        }
    
        .mission-right-pane{
            padding-top: 50px;
        }
    
        /* brief summary tab */
        .brief-wrapper {
            margin: 10px;
            padding-top: 16px;
            margin-left: 0px;
            overflow-x: auto;
            height: calc(75vh - 91px);
        }
        .brief-title {
            color: ${({ theme }) => theme.campaign_title};
            font-size: 18px;
            text-align: center;
        }
        .brief-summary {
            padding-top: 24px;
            width: 60%;
            text-align: leftPlease enter target name;
            margin: 0 auto;
        }
        .video-wrapper {
            margin: 0 auto;
            text-align: center;
            padding-top: 32px;
        }
        .brief-video {
            width: 50%;
            position: relative;
            //box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 -0.5px 4px 0 rgba(${({ theme }) =>
              theme.campaigns_video_brief_video_shadow}, 0.35), 0 1px 4px 0 rgba(${({
  theme,
}) => theme.campaigns_video_brief_video_shadow}, 0.35);
            border-radius: 0.4em;
        }

        /* campaign card */
        .campaign-grid {
            padding-top: 12px;
            padding-left: 32px;
            padding-bottom: 12px;
            text-align: center;
            color: ${({ theme }) => theme.text};
            background-color: ${({ theme }) => theme.campaigns_grid};
        }
        .campaign-title {
            color: ${({ theme }) => theme.text};
            font-size: 18px;
            font-weight: bold;
        }
        .campaign-subtitle {
            color: ${({ theme }) => theme.text};
            font-size: 16px;
        }
        .campaign-hline {
            border-bottom: 1px solid ${({ theme }) => theme.campaign_hline};
            margin-top: 12px;
            margin-right: 12px;
            margin-bottom: 12px;
        }
        .campaign-info {
            color: ${({ theme }) => theme.campaign_info_text};
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            margin: unset;
            padding: unset;
        }
        .campaign-info-vline {
            color: ${({ theme }) => theme.campaign_info_vline_text};
            font-size: 12px;
            font-weight: bold;
            padding-right:20px;
        }
        .campaign-grid-vline {
            border-left-style: solid;
            border-left: 2px solid ${({ theme }) => theme.campaign_info_vline};
            ${({ theme }) => theme.campaigns_campaign_grid_vline_style};
        }
        .campaign-section-title {
            color: ${({ theme }) => theme.campaign_section_text};
            font-size: 12px;
            font-weight: bold;
            padding-top: 5px;
        }
        .campaign-topics {
            color: ${({ theme }) => theme.campaign_topics_text};
            font-size: 12px;
            padding-top: 8px;
            text-align: left;
            margin-right: 8px;
        }  
        .campaign-industries {
            color: ${({ theme }) => theme.campaign_industries_text};
            font-size: 12px;
            padding-top: 8px;
            text-align: left;
            margin-right: 8px;
        } 
        .campaign-button-grid {
            padding: 40px 64px 32px 64px;
        }         




        // TEAMS (part of CAMPAIGN)

        .campaign-teams-dnd {

            height: 680px;
            overflow: scroll;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px;
                //border: 2px solid white; /* should match background, can't be transparent */
                background-color: rgba(white, 0.2);
                
            }
        }

        .all-teams-title-textbox{
            display: flex;
            justify-content: space-between;
                
                .all-teams-title {
                    color: ${({ theme }) => theme.teams_title};
                    font-size: 18px;
                    font-weight: bold;
                    padding-left: 16px;
                }

                .teams-dnd-search-box{
                    margin-bottom: unset !important;
                }

                .search-input-teams-dnd{
                    background-color: ${({ theme }) =>
                      theme.campaigns_grid} !important;
                }
        }

        .selected-teams-title {
            color: ${({ theme }) => theme.teams_title};
            font-size: 18px;
            font-weight: bold;
        }
    
        .all-teams-hline {
            border-bottom: 1px solid ${({ theme }) => theme.teams_hline};
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 16px;
        }
    
        .selected-teams-hline {
            border-bottom: 1px solid ${({ theme }) => theme.teams_hline};
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 16px;
        }
    
        .team-container {
            background-color: ${({ theme }) => theme.teams_container};
            border: 1px solid ${({ theme }) => theme.teams_container_border};
            margin-bottom: 20px;
            border-top: 12px solid transparent;
            border-image: url(${team_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            //transition: all .4s ease-in-out;
            //border-radius: 0.2em;
        }
    
        .team-container:hover {
            background-color: ${({ theme }) => theme.teams_container};
            //border: 1px solid ${({ theme }) => theme.teams_container_border};
            margin-bottom: 20px;
            border-top: 12px solid transparent;
            border-image: url(${team_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            //transform: scale(1.010);
            //border-radius: 0.2em;
        }
    
        .teams-border {
            border: 1px solid ${({ theme }) => theme.teams_border};
            height: 100%;
        }
    
        .team-content {
            margin: 10px;
            height: 100px;
        }
    
        .team-hline {
            border-bottom: 1px solid ${({ theme }) => theme.teams_hline};
            margin-top: 5px;
            margin-bottom: 5px;
        }
    
        .team-vline {
            border-left-width: 2px;
            border-left-style: solid;
            border-image: 
              linear-gradient(
                to bottom, 
                ${({ theme }) => theme.teams_container},
                ${({ theme }) => theme.teams_border},
                ${({ theme }) => theme.teams_container}
              ) 1 100%;
              
              .plus-btn-cympire-color{
                  border-radius: 50%;
                  width: 20px;
                  height: 20px;
                  font-size: 14px;
                  background-color: #49bd95;
                  position: absolute;
                  top: 5px;
                  right: 0px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
              .plus-btn-cympire-color:hover{
                  opacity: 0.65;
                  cursor: pointer !important;
              }            
        }
    
        .t-avatar {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50em;
            width: 64px;
            height: 64px;
            font-size: 14.4px;
            padding-top: 25px;
        }
    
        .t-avatar-img {
            width: 64px;
            height: 64px;
            border-radius: 50em;
            border: 1px solid ${({ theme }) => theme.teams_avatar_image_border};
        }
    
        .team-info {
            padding-left: 40px;
            padding-top: 24px;
        }
    
        .team-title {
            color: ${({ theme }) => theme.teams_team_title};
            font-size: 16px;
        }
        .team-subtitle {
            color: ${({ theme }) => theme.teams_team_subtitle};
            font-size: 13px;
        }
    
        .team-users {
            margin: 20px;
            max-height: 64px;
        }
    
        .team-player {
            width: 100%;
        }
    
        .align-center {
            text-align: center;
            display: block;
        }
        

        .t-avatar-event-teams-player {
            width: 48px !important;
            height: 48px !important;
            border-radius: 50em;
            border: 1px solid ${({ theme }) => theme.teams_avatar_image_border};
            //margin-left: 4px;        
        }



        //EVENT (part of CAMPAIGN)
        .time-input {
            width: 200px;
        }
        .date-input {
            width: 200px;
        }

        .campaign-event-card {
            background-color: ${({ theme }) => theme.background};
            border: unset
        }

        .campaign-event-card-header {
            background-color: ${({ theme }) => theme.background};
            border-bottom: 1px solid ${({ theme }) => theme.form_border};
            font-size: 18px;
        }

        /* PACKAGES */

        .package-wrapper{
            width: 100%;
            height: 100%;
            padding-bottom: 16px; 
        }
    
        .package-container-mixed {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${mixed_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
        }
        .package-container-mixed:hover {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${mixed_camp_mark}) 30px stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transform: scale(1.025);
        }
    
        .package-container-red {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${red_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
        }
        .package-container-red:hover {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${red_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transform: scale(1.025);
        }
    
        .package-container-blue {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${blue_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
        }
        .package-container-blue:hover {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid transparent;
            border-image: url(${blue_camp_mark}) 30 stretch;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transform: scale(1.025);
        }
    
        .package-container-blog {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid #dfdbbd; 
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
        }
        .package-container-blog:hover {
            margin-left: 4px;
            margin-top: 16px;
            width: 100%;
            border-left: 12px solid #dfdbbd; 
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transform: scale(1.025);
        }
    
        .package-grid {
            padding-top: 12px;
            padding-left: 32px;
            padding-bottom: 12px;
            text-align: center;
            color: black;
            background-color: ${({ theme }) => theme.package_grid};
        }
        .package-title {
            color: ${({ theme }) => theme.package_text};
            font-size: 18px;
            font-weight: bold;
        }
        .package-subtitle {
            color: ${({ theme }) => theme.package_text};
            font-size: 15px;
            text-align: left;
        }
        .package-hline {
            border-bottom: 1px solid ${({ theme }) => theme.package_hline};
            margin-top: 12px;
            margin-right: 12px;
            margin-bottom: 12px;
        }
        .package-info {
            color: ${({ theme }) => theme.package_info_text};
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            margin: unset;
            padding: unset;
        }
        .package-enable {
            margin-top: 10px;
        }
        .package-enable-title {
            color: ${({ theme }) => theme.package_info_text};
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            margin: unset;
            padding: unset;
        }
        .package-enable-switch {
            text-align: left;
        }
    
        .package-info-vline {
            color: ${({ theme }) => theme.package_info_text};
            font-size: 12px;
            font-weight: bold;
            padding-right:20px;
        }
        .package-grid-vline {
            border-left-width: 2px;
            border-left-style: solid;
            ${({ theme }) => theme.package_grid_vline_style};
        }
        .package-section-title {
            color: ${({ theme }) => theme.package_section_title};
            font-size: 12px;
            font-weight: bold;
            padding-top: 5px;
        }
        .package-topics {
            color: ${({ theme }) => theme.package_topics};
            font-size: 12px;
            padding-top: 8px;
            text-align: left;
            padding-right: 10px;
        }  
        .package-industries {
            color: ${({ theme }) => theme.package_industries};
            font-size: 12px;
            padding-top: 8px;
            text-align: left;
        } 
        .package-image {
            //padding-top: 10px;
            margin-top: 10px;
            width:90%;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.4), 0 1px 2px 0 rgba(0, 0, 0, 0.4);
            border-radius: 0.4em;
        }
        .package-button-grid {
            padding: 48px 64px 32px 64px;
        }         



        .add-package-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .add-package-button:active{
            background-color: ${({ theme }) => theme.button};
        }
        .add-package-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .add-package-button:not(:disabled):not(.disabled):active:focus, .show > .add-package-button.dropdown-toggle:focus {
            box-shadow: unset;
        }








        /* TEAMS */
        
        .teams-table-container {
            overflow-y: scroll;
            //height: 100vh;
        }


        .teams-table {
            border: unset;
            
            .table-responsive {
                overflow-x: unset;
                overflow-y: unset;
            }

            background-color: transparent;
            
            .table {
                color: ${({ theme }) => theme.table_text};
            }
    
            .table th {
                border: unset;
                background-color: ${({ theme }) => theme.table_header}; 
            }
    
            .table th:hover {
                background-color: ${({ theme }) =>
                  theme.table_header_hover}; //rgba($color: #152433, $alpha: 0.9);
            }
            
            .table th:nth-child(1){
                border: unset;
                border-radius:5px 0px 0px 5px;
            }
            .table th:nth-child(1):hover{
                background-color: ${({ theme }) => theme.table_header};
            }
            .table th:nth-child(6){
                border: unset;
                border-radius:0px 5px 5px 0px;
            }
            .table th:nth-child(6):hover{
                background-color: ${({ theme }) => theme.table_header};
            }
    
            .table tr:first-child td{
                border: unset;
            }
    
            .table tr:hover {
                padding: 0.5rem;
                padding-left: 1px;
            }
    
            .table-hover tbody tr:hover {
                background-color: ${({ theme }) => theme.table_row_hover};
                color: ${({ theme }) => theme.table_text};
            }
    
            .table td {
                padding: 0.5rem;
            }
    
            .manage-buttons {
                //margin-top: 12px;
                width: 100%;
                padding-right: 4px;
            }
    
    
            .add-team-button{
                width: 120px;
                height: 28px;
                background-color: ${({ theme }) => theme.button};
                border:  1px solid ${({ theme }) => theme.button_border};
                color: ${({ theme }) => theme.button_text};
                border-radius: 0.6em;
                font-weight: 500;
                float: left;
                font-size: 11px;
                padding-top: 6px;
            }
        
            .add-team-button:active{
                background-color: ${({ theme }) => theme.button};   
            }
            .add-team-button:hover{
                border-color: ${({ theme }) => theme.button_border_hover};
                color: ${({ theme }) => theme.button_text_hover};
            }
            .add-team-button:not(:disabled):not(.disabled):active:focus, .show > .add-team-button.dropdown-toggle:focus {
                box-shadow: unset;
            }
            
            
            .update-team-button-table{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) => theme.btn_update};
                border:  1px solid ${({ theme }) => theme.btn_update_border};
                color: ${({ theme }) => theme.btn_update_text};
                border-radius: 0.6em;
                font-weight: 500;
                //float: left;
                font-size: 10px;
                padding: unset;
                margin-right: 8px;
            }

            .update-team-button-table:focus{
                outline: unset;
            }
            .update-team-button-table:active{
                background-color: ${({ theme }) => theme.btn_update};   
                outline: unset;
            }
            .update-team-button-table:hover{
                border-color: ${({ theme }) => theme.btn_update_hover};
                color: ${({ theme }) => theme.btn_update_hover};
            }
            .update-team-button-table:not(:disabled):not(.disabled):active:focus, .show > .update-team-button-table.dropdown-toggle:focus {
                box-shadow: unset;
            }

            .update-team-button-table:disabled {
                border-color: ${({ theme }) => theme.btn_update_border};
                color: ${({ theme }) => theme.btn_update_text};
                opacity: 0.20;
            }


    
    
            .delete-team-button-table{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) => theme.btn_danger};
                border:  1px solid ${({ theme }) => theme.btn_danger_border};
                color: ${({ theme }) => theme.btn_danger_text};
                border-radius: 0.6em;
                font-weight: 500;
                font-size: 10px;
                padding: unset;
                //padding-top: 2px;
            }
    
            .delete-team-button-table:active{
                background-color: ${({ theme }) => theme.btn_danger};   
            }
            .delete-team-button-table:hover{
                border-color: ${({ theme }) => theme.btn_danger_hover};
                color: ${({ theme }) => theme.btn_danger_hover};
            }
            .delete-team-button-table:not(:disabled):not(.disabled):active:focus, .show > .delete-team-button-table.dropdown-toggle:focus {
                box-shadow: unset;
            }
    
            .card-body {
                min-height: unset;
                padding-top: 0rem;
            }
            
    
        }
    
    
        .teams-table-player {
            margin-right: 8px;
        }
    
        .c-avatar-teams {
            margin-top: 10px;
        }
    
        .c-avatar-img-teams {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.teams_avatar_image_border};
        }
    
        .c-avatar-img-teams-player {
            width: 85%;
            height: 85%;
            border-radius: 50em;
            border: 1px solid ${({ theme }) => theme.teams_avatar_image_border};
            margin-left: 4px;        
        }
    
    
        .c-avatar-team-player-status {
            margin-right: 2px;
            margin-bottom: 2px;
        }
    
        .team-players-col {
            text-align: center;
    
            .player-name {
                font-size: 10px;
                color: ${({ theme }) => theme.teams_player_text};
            }
    
            .pointer {
                cursor: pointer;
            }
        }
    
    
    
    
    
        //fields
        // .icon {
        //     width: 60px;
        // }
        .icon {
            display: inline-block;
            color: inherit;
            text-align: center;
            fill: currentColor;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size) {
            width: 1rem;
            height: 1rem;
            font-size: 1rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-xxl {
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-3xl {
            width: 3rem;
            height: 3rem;
            font-size: 3rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-4xl {
            width: 4rem;
            height: 4rem;
            font-size: 4rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-5xl {
            width: 5rem;
            height: 5rem;
            font-size: 5rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-6xl {
            width: 6rem;
            height: 6rem;
            font-size: 6rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-7xl {
            width: 7rem;
            height: 7rem;
            font-size: 7rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-8xl {
            width: 8rem;
            height: 8rem;
            font-size: 8rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-9xl {
            width: 9rem;
            height: 9rem;
            font-size: 9rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-xl {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.5rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
            width: 1.25rem;
            height: 1.25rem;
            font-size: 1.25rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-sm {
            width: 0.875rem;
            height: 0.875rem;
            font-size: 0.875rem;
          }

        .name {
            vertical-align: middle;
        }
        .points {
            vertical-align: middle;
        }
        .registered {
            vertical-align: middle;
        }
        .show_details {
            vertical-align: middle;
        } 
    
        .team-players-select {
            margin-bottom: 20px;
        }
        
        .col-6 {
            padding-right: 6px;
            padding-left: 6px;
            //margin-right: 1px;
        }


        .team-players-col {
            -webkit-flex: 0 0 33%;
            -ms-flex: 0 0 33%;
            flex: 0 0 33%;
            max-width: 33%;
        }


        .team-disabled-notification {
            font-size: 12px;
            color: ${({ theme }) => theme.team_disabled_notification_text};
        }


        // TEAM FORMS (ADD, UPDATE)

        .add-team {
            border: unset;
        }
    
        .team-form {
            padding: unset;
            padding-top: 24px;
        }
    
        .form-title {
            padding: unset;
            padding-bottom: 8px;
            margin-bottom: 24px;
            margin-left: 0px;
            margin-right: 0px;
    
            border-bottom: 1px solid;
            background-color: #131313;
            border-color: #d8dbe0;   
            font-size: 18px;
        }
    
        .form-header {
            margin-bottom: 12px;
        }    
    
        .form-header-title {
            margin-top: 14px;
            font-size: 24px;
        }
    
        .update-team-form-header-title {
            margin-top: 14px;
            margin-left: 16px;
        }
    
    
        .update-team-title {
            margin-bottom: 0px;
            font-size: 24px;
        }
    
        .update-team-subtitle {
            color: ${({ theme }) => theme.form_subtitle};
        }


        .info {
            color: ${({ theme }) => theme.form_field_info};
            font-size: 11px;
            margin-top: 8px;
            margin-bottom: 0px;
        }
    
        .warning {
            color: ${({ theme }) => theme.form_field_warning};
            font-size: 11px;
            margin-top: 8px;
            margin-bottom: 0px;
        }
    
        .danger {
            color: #FF6A67;
            background-color: transparent;
            border: 1px solid #FF6A67;
            //font-size: 11px;
            margin-top: 8px;
            margin-bottom: 0px;
        }
    
        .success {
            color: #49bd95;
            background-color: transparent;
            border: 1px solid #49bd95;
            //font-size: 11px;
            margin-top: 8px;
            margin-bottom: 0px;
        }
    
        .create-team-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_update};
            border:  1px solid ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
    
        .create-team-button:hover{
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
        }
    
        .create-team-button:disabled {
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
            opacity: 0.20;
        }
    
        .create-team-button:focus {
            outline: unset;
        }
    
    

        .update-team-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_update};
            border:  1px solid ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
    
        .update-team-button:hover{
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
        }
    
        .update-team-button:disabled {
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
            opacity: 0.20;
        }
    
        .update-team-button:focus {
            outline: unset;
        }



        .cancel-create-team-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color:  #131313;;
            border: 1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 7px;
            margin-right: 8px;
            //margin-right: 32px;
        }
    
        .cancel-create-team-button:hover{
            border-color: ${({ theme }) => theme.button_hover};
            color: ${({ theme }) => theme.button_hover};
        }
    

        .cancel-update-team-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border: 1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 7px;
            margin-right: 8px;
            //margin-right: 32px;
        }
    
        .cancel-update-team-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }



        .form-hline {
            border-top: 1px solid ${({ theme }) => theme.form_hline};
            margin-top: 24px;
        }
    
        .form-buttons {
            float: right;
            padding-right: 0px;
            margin-right: 0px;
        }







        /* USERS */

        // message
        .messages {
            margin-left: 20px;
            //padding-right: 56px;
        }
    
        .first-class {
            margin-left: 10px;
        }
    
        // counter
        .counter {
            vertical-align: middle;
            margin-top: 4px;
            color: #4f5d73;
            font-style: italic;
            width: 1200px;
        }

        //table
        .users-table {
            border: unset;

            background-color: transparent;
            
            .table {
                color: ${({ theme }) => theme.table_text};
            }
            
            .table th {
                border: unset;
            }
    
            .table th:hover {
                background-color: ${({ theme }) => theme.table_header_hover};
            }
    
            .table th:nth-child(1){
                border: unset;
                border-radius:5px 0px 0px 5px;
            }
            .table th:nth-child(1):hover{
                background-color: ${({ theme }) => theme.table_header};
            }
    
            .table th:nth-child(7){
                border: unset;
                border-radius:0px 5px 5px 0px;
            }
            .table th:nth-child(7):hover{
                background-color: ${({ theme }) => theme.table_header};
            }
    
            .table tr:first-child td{
                border: unset;
            }
    
            .table tr:hover {
                padding: 0.5rem;
                padding-left: 1px;
            }
    
            .table-hover tbody tr:hover {
                background-color: ${({ theme }) => theme.table_row_hover};
                color: ${({ theme }) => theme.table_text};
            }
    
            .table td {
                padding: 0.5rem;
                margin-bottom: 10px;
            }
    
            .add-user-button{
                width: 120px;
                height: 28px;
                background-color: ${({ theme }) => theme.button};
                border:  1px solid ${({ theme }) => theme.button_border};
                color: ${({ theme }) => theme.button_text};
                border-radius: 0.6em;
                font-weight: 500;
                float: left;
                font-size: 11px;
                padding-top: 6px;
            }
    
            .add-user-button:active{
                background-color: ${({ theme }) => theme.button};   
            }
            .add-user-button:hover{
                border-color: ${({ theme }) => theme.button_border_hover};
                color: ${({ theme }) => theme.button_text_hover};
            }
            .add-user-button:not(:disabled):not(.disabled):active:focus, .show > .add-user-button.dropdown-toggle:focus {
                box-shadow: unset;
            }
        

    
            .update-user-button-table{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) => theme.btn_update};
                border:  1px solid ${({ theme }) => theme.btn_update_border};
                color: ${({ theme }) => theme.btn_update_text};
                border-radius: 0.6em;
                font-weight: 500;
                float: left;
                font-size: 10px;
                padding-top: 4px;
                padding: unset;
            }
    
            .update-user-button-table:active{
                background-color:${({ theme }) => theme.btn_update};   
            }
            .update-user-button-table:hover{
                border-color: ${({ theme }) => theme.btn_update_hover};
                color: ${({ theme }) => theme.btn_update_hover};
            }
            .update-user-button-table:not(:disabled):not(.disabled):active:focus, .show > .update-user-button-table.dropdown-toggle:focus {
                box-shadow: unset;
            }
    
    
            .delete-user-button-table{
                width: 100%;
                height: 28px;
                background-color: ${({ theme }) => theme.btn_danger};
                border:  1px solid ${({ theme }) => theme.btn_danger_border};
                color: ${({ theme }) => theme.btn_danger_text};
                border-radius: 0.6em;
                font-weight: 500;
                font-size: 10px;
                padding-top: 4px;
                padding: unset;
            }
    
            .delete-user-button-table:active{
                background-color: ${({ theme }) => theme.btn_danger};   
            }
            .delete-user-button-table:hover{
                border-color: ${({ theme }) => theme.btn_danger_hover};
                color: ${({ theme }) => theme.btn_danger_hover};
            }
            .delete-user-button-table:not(:disabled):not(.disabled):active:focus, .show > .delete-user-button-table.dropdown-toggle:focus {
                box-shadow: unset;
            }
            
            
            .card-body {
                min-height: unset;
                padding-top: 0rem;
            }
    
            .manage-buttons {
                .col-6 {
                    padding-right: 5px;
                    padding-left: 5px;
                }
                padding-right: 10px;
            }
            
        }
    
    
        .c-avatar-img-users {
            width: 36px;
            height: 36px;
            border-radius: 50em;
            border: 1px solid ${({ theme }) => theme.users_avatar_image_border};
        }
    
       
        .c-avatar-img {
            width: 48px !important;
            height: 48px  !important;
            border-radius: 50em;
        }
        
        //fields
        .name {
            vertical-align: middle;
        }
        .email {
            vertical-align: middle;
        }
        .organization {
            vertical-align: middle;
        }
        .score {
            text-align: left;
            vertical-align: middle;
        }
        .role {
            vertical-align: middle;
        }
        .show_details {
            vertical-align: middle;
        }         

        


        // USERS FORMS (ADD, UPDATE)
        .add-user {
            border: unset;
        }
        .user-form {
            padding: unset;
            padding-top: 24px;
        }

        .tooltip {
            color: ${({ theme }) => theme.tooltip_text};
            font-size: 10px;
        }
          
    
        .password-show-button {
            font-size: 10px !important;
            margin-top: -15px !important;
            padding: 2px 5px !important;
            background: ${({ theme }) => theme.button} !important;
            color: ${({ theme }) => theme.button_text} !important;
        }
    
    
        .create-user-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_create};
            border:  1px solid ${({ theme }) => theme.btn_create_border};
            color: ${({ theme }) => theme.btn_create_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
    
        .create-user-button:hover{
            border-color: ${({ theme }) => theme.btn_create_hover};
            color: ${({ theme }) => theme.btn_create_hover};
        }
    
        .create-user-button:disabled {
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_create_hover};
            opacity: 0.45;
        }
        .create-user-button:focus{
            outline: unset;
        }
    
    
    
    
        .cancel-create-user-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: #131313;
            border: 1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 7px;
            margin-right: 8px;
            //margin-right: 32px;
        }
    
        .cancel-create-user-button:hover{
            border-color: ${({ theme }) => theme.button_hover};
            color: ${({ theme }) => theme.button_hover};
        }




        .update-user {
            border: unset;
        }
    
        .update-form-title {
            padding: unset;
            padding-bottom: 8px;
            margin-bottom: 24px;
            margin-left: 0px;
            margin-right: 0px;
    
            border-bottom: 1px solid;
            
            border-color: #d8dbe0;   
            font-size: 18px;
        }
    
        .update-form-header {
            background-color: ${({ theme }) => theme.form};
            margin-bottom: 12px;
        }
    
        .update-form-header-title {
            margin-top: 14px;
            margin-left: 16px;
        }
    
    
        .update-user-title {
            margin-bottom: 0px;
            font-size: 24px;
        }
    
        .update-user-subtitle {
            color: ${({ theme }) => theme.form_subtitle};
        }



        .update-user-form-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_update};
            border:  1px solid ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
    
        .update-user-form-button:hover{
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
        }
    
        .update-user-form-button:disabled {
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
            opacity: 0.20;
        }
    
        .update-user-form-button:focus{
            outline: unset;
        }
    


    
        .cancel-update-user-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border: 1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
            margin-right: 8px;
            //margin-right: 32px;
        }
    
        .cancel-update-user-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }





        /* SETTINGS */
        .settings-card {
            border: unset;
        }
    
        .settings-form {
            padding: unset;
            padding-top: 24px;
        }  

        .textarea-input {
            resize: none;
        }

        .update-settings-button {
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_update};
            border:  1px solid ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
        .update-settings-button:hover{
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
        }
    
        .update-settings-button:disabled {
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
            opacity: 0.20;
        }
    
        .update-settings-button:focus{
            outline: unset;
        }




        /* CAMPAIGN BUILDER */

        // CAMPAIGNS

        /* buttons */
        .add-campaign-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .add-campaign-button:active{
            background-color: ${({ theme }) => theme.button};   
        }
        .add-campaign-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .add-campaign-button:not(:disabled):not(.disabled):active:focus, .show > .add-campaign-button.dropdown-toggle:focus {
            box-shadow: unset;
        }




        // CAMPAIGN

        .add-target-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .add-target-button:active{
            background-color: ${({ theme }) => theme.button};   
        }
        .add-target-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .add-target-button:not(:disabled):not(.disabled):active:focus, .show > .add-target-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    



        .brief-summary-input {
            resize: none;
        }
    
        .edit-button-row {
            padding-bottom: 20px;
        }




        // ADD CAMPAIGN MODAL
        .campaign-card {
            border: unset;
        }
    
        .campaign-form {
            padding: unset;
            padding-top: 24px;
        }    

        .create-campaign-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_create};
            border:  1px solid ${({ theme }) => theme.btn_create_border};
            color: ${({ theme }) => theme.btn_create_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 3px;
            //margin-right: 32px;
        }
    
        .create-campaign-button:hover{
            border-color: ${({ theme }) => theme.btn_create_hover};
            color: ${({ theme }) => theme.btn_create_hover};
        }
    
        .create-campaign-button:disabled {
            border-color: ${({ theme }) => theme.btn_create_hover};
            color: ${({ theme }) => theme.btn_create_hover};
            opacity: 0.20;
        }
        .create-campaign-button:focus{
            outline: unset;
        }
    
    
    
        .cancel-create-campaign-button{
            margin-top: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border: 1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 7px;
            margin-right: 8px;
            //margin-right: 32px;
        }
    
        .cancel-create-campaign-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }





        /* LICENSE */
        .license-wrapper{
            width: 100%;
            height: 100%;
            padding-bottom: 16px;
        }
    
        .license-container {
            margin-top: 16px;
            width: 100%;
            border-left-width: 12px;
            border-left-style: solid;
            border-image: 
              linear-gradient(
                to bottom, 
                rgb(40, 121, 107), 
                rgba(1, 252, 173, 0.65)
              ) 1 100%;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        }
    
        .license-grid {
            padding-top: 12px;
            padding-left: 32px;
            padding-bottom: 12px;
            text-align: center;
            color: ${({ theme }) => theme.license_grid_text};
            background-color: ${({ theme }) => theme.license_grid};
        }
        .license-title {
            color: ${({ theme }) => theme.license_grid_text};
            font-size: 18px;
            font-weight: bold;
        }
        .license-subtitle {
            color: ${({ theme }) => theme.license_grid_text};
            font-size: 16px;
        }
        .license-hline {
            border-bottom: 1px solid ${({ theme }) => theme.license_hline};
            margin-top: 12px;
            margin-right: 12px;
            margin-bottom: 12px;
        }
        .license-info {
            color: ${({ theme }) => theme.campaigns_info_text};
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            margin: unset;
            padding: unset;
        }
        .license-info-vline {
            color: ${({ theme }) => theme.license_info_vline_text};
            font-size: 12px;
            font-weight: bold;
            padding-right:20px;
        }
        .license-grid-vline {
            border-left-style: solid;
            border-left: 2px solid ${({ theme }) =>
              theme.license_grid_vline_border};
            ${({ theme }) => theme.license_campaign_grid_vline_style};
        }
        .license-section-title {
            color: rgba($color: black, $alpha: 0.8);
            font-size: 14spx;
            font-weight: bold;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .license-topics {
            color: rgba($color: black, $alpha: 0.8);
            font-size: 12px;
            padding-top: 8px;
            text-align: left;
            padding-right: 10px;
        }  
        .license-industries {
            color: rgba($color: black, $alpha: 0.8);
            font-size: 12px;
            padding-top: 8px;
            text-align: left;
        } 
        .license-image {
            padding-top: 10px;
            width:90%;
        }
        .license-button-grid {
            margin: 10px;
        } 
        .text-bold {
            font-weight: bold;
            padding-right: 5px;
        } 
        


        .add-license-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .add-license-button:active{
            background-color: ${({ theme }) => theme.button};
        }
        .add-license-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .add-license-button:not(:disabled):not(.disabled):active:focus, .show > .add-license-button.dropdown-toggle:focus {
            box-shadow: unset;
        }

        .license-buttons {
            padding-right: 16px;
            margin-top: 16px;
            .form-control:focus {
                color: unset;
                background-color: unset;
                border-color: unset;
                outline: 0;
                box-shadow: unset;
            }
    
        }



        /* Tenants */
        .license-valid {
            vertical-align: middle;
        }
        .license-module {
            vertical-align: middle;
        }



        /* NETWORK MAP (Part of attacker) */

        .loaderCimpire{
            width :168px;
            height:168px
        }
        
        .NetworkMap{
            height: 100%;
            width: 100%;
            font-family: 'Roboto', sans-serif !important;
            padding: 0px 16px;
            border: 1px solid ${({ theme }) => theme.network_map_border};
            background-color: ${({ theme }) => theme.network_map_background};
            border-radius: 1em;
            margin: 20px;
        }
        
        .dropHere{
            opacity: 0.3;
            font-size: 12px;
        }
        
        .network-grid{
            display: flex;
            height: calc(100% - 100px);
            margin: 20px;
        }
        
        
        .network-objects-grid-vline {
            margin-right: 20px;
            border-right-style: solid;
            border-right: 2px solid rgba($color: black, $alpha: 0.1);
            ${({ theme }) => theme.network_map_vline_style}
        }
        
        
        .inputNameModal:focus{
           outline: none; 
        }
        .inputAndEdit{
            display: flex;
            align-items: center;
        }
        .editInputNameModal{
            user-select: none;
        }
        
        .inputNameModal{
            box-sizing: border-box;
            border: none;
            max-width: 340px;
            background-color: #000;
        }
        .right-panel-network{
            flex: 5;
            text-align: center;
            /*max-width: 970px;*/
        }
        
        .sidebar-network{
            margin-right: 20px;
            flex: 1;
            text-align: center;
            //background-color: rgb(248, 248, 247);
            color: grey;
            border: 1px solid ${({ theme }) => theme.network_map_border};
            border-radius: 1em;
            padding: 10px;
            height: 100%;
        }
        
        
        .sidebarBody{
        padding: 8px;
        }
        .selectType{
            text-align: start;
            font-size: 12px;
            color: ${({ theme }) => theme.network_map_text};
        }
        
        .nav.nav-tabs{
            margin: 0 auto;
        }
        
        .classesListPaints{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
        .newworkmapPaints{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 4px;
            padding-top: 10px;
        } 
        
        .iconNetwork{
            user-select: none;
            display: flex;
            justify-content: space-between;
            background-color: ${({ theme }) => theme.network_map_object};
            margin-bottom: 8px;
            padding: 0px 8px;
            align-items: center;
            box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            font-size: 12px;
            height:24px
        }
        
        .iconInstance{
            background-color: ${({ theme }) => theme.network_map_background};
            height: 54px;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            margin: 0 auto;
            cursor: grab !important;
            box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.2);
            border-radius: 2px;
            font-size: 11px;   
        }
        
        .iconInstance :first-child{
            margin-top: 1px;
        }
        .iconInstance:hover{
            -webkit-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.05);
            -moz-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.05);
            box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.05);
            transition: 0.3s;
            transform: translateY(-1px);
        }
        
        .iconNetwork:hover{
            cursor: grab !important;
            -webkit-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.05);
            -moz-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.05);
            box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.05);
            transition: 0.3s;
            transform: translateY(-1px);
        }
        
        
        .paintsSidebar{
            margin-top: 5px;
        }
        
        .nav-link{
            cursor: pointer;
            //min-width: 88px;
            user-select: none;
        }
        .network-specificRow{
            border: dashed 1px ${({ theme }) =>
              theme.network_map_segment_border};
            border-radius: 2px;
            text-align: start;
            padding: 0px 5px;
            height: 110px;
            margin: 0 auto;
            margin-bottom: 15px;
            display: flex;
            /*max-width: 740px;*/
            padding-top: 10px;
        }
        
        .dnd-field{
            min-height: 200px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: lightgray;
            font-weight: bold;
        }
        .dnd-wrapper{
            padding: 0px 5px;
            max-width: 1332px;
        }
        .specificClass{
            border: 1px solid ${({ theme }) => theme.network_map_border};
            border-radius: 4px;
            overflow-y:hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            color:grey !important;
            height: 70px;
            position: relative;
            margin: 5px 5px;
        }
        .dropInstance{
            height: 100px;
        }
        
          .specificIconInstance{
              width: 30px;
              height: 30px;
          }
          .networkWrapper{
             
              height: 70px;
          }
          .classTitle{
              font-weight: 300;
              margin: 0px 2px;
          }
          .subnetNameDnd{
              margin-right: 2px;
              margin-left: 6px;
              color: ${({ theme }) => theme.network_map_text};
          }
        
        .networkWrapper.C{
            width: 33.333333%;
        }
        .networkWrapper.B{
            width: 50%;
        }
        .networkWrapper.A{
            width: 100%;
        }
        .objectLine{
            box-sizing: border-box;
            font-size: 1rem;
            text-align: center;
        }
        .networkTitle{
            display: flex;
            align-items: center;
            font-weight: bold;
            color: grey;
            font-size: 12px;
        }
        .titleWrapper{
            width: fit-content;
            cursor: pointer!important;
        }
        .opacityHover:hover{
            cursor: pointer;
            opacity: 0.65;
            user-select: none;
        }
        .bold{
            font-weight: bold;
        }
        .campaignBuilderNetworkMapTitle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            //background-color: #353535;
            color: ${({ theme }) => theme.network_map_text};
            font-size: 14px;
            padding: 2px 5px;
            border-bottom: 1px solid ${({ theme }) => theme.network_map_border};
        }
        .objectOpacitySize{
            font-size: 10px;
        }
        .selectMachine{
            text-align: start;
            font-size: 12px;
            color: grey;
            padding-bottom: 8px;
            user-select: none;
        }
        /*
        .lampSubent{
            margin: 0px 6px;
        }
        */
        /*glass input*/
        .search-box {
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            border-radius: unset;
            height: 28px;
        }
        
        .input-group.search-box{
            margin-bottom: 5px;
        }
        #search-textbox{
            background-color: ${({ theme }) => theme.network_map_background};
            
        }
        #search-textbox:focus{
          box-shadow: none;
        }
        .centerButtonNMap{
            justify-content: center;
            align-items: center;
            display: flex;
        }
        
        /*end glass input*/
        @media screen and (max-width: 1600px) {
           .dnd-wrapper{
            max-width: 1055px;
           }
          
          }
        
        .saveBtnModal{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 30px;
            color: #fff;
            width: 90px;
            font-size: 12px;
            height: 24px;
            background: #49bd95;
            border-radius: 4px;
        }
        .cancelBtnModal{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 30px;
            color: #7F7F7F;
            width: 90px;
            font-size: 12px;
            font-weight: bold;
            height: 24px;
            background: #EBEDEF;
            border-radius: 4px;
        }
        
        .createNetworkBtn{
            padding: 6px 30px;
            color: #fff;
            font-size: 12px;
            background: #152433;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 32px;
            text-align: center;
            
        }
        .createNetworkWrapperDiv{
            display: flex;
            justify-content: center;
        }
        .marginAuto{
            margin: 0 auto !important;
        }
        .svgAtSubnetFromGrid{
            width: 40px;
            height: 40px;
        }
        .redColor{
            color:red;
        }
        
        
        // modal.scss (network-map)

        .labelSpan.editModal{
            margin-right: 15px;
        }
        .bodyEditSubnetModal{
            text-align: start;
            padding: 8px 0px;
        }
        .subnetCidrModalInput{
            width: inherit;
            border: none;
            outline: unset;
            font-size: 16px;  
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; 
        }
        
        .editDividerModal{
            width: 0.5px;
            margin: 6px;
            background-color: grey;
            height: 11px;
            display: inline-block;
        }
        
        .editSubnetModalFooter{
            justify-content: center !important;
        }
        
        .modal-header{
            font-size: 15px;
        }

        .modal-body{
            //font-size: 12px !important;
        }
        
        .modal-header.radio{
            font-size: 12px;
        }
        
        .headerModalEditSubnet{
            padding-top: 32px;
            padding-left: unset;
            padding-right: unset;
            display: flex;
        }
        
        .bodyModalVerificationSubnet{
            padding: 20px 0px;
        }
        .footerModalVerificationModal{
            border-top: unset;
            justify-content: center;
        }



        /*instance modal*/
        
        .headerModalInstanceDetails{
        display: flex;
        padding: 16px 0px;
        margin: 16px;
        }
        .modalBodyInstanceDetails{
            padding: 20px 0px;
        }
        .modalHeaderInstanceVerification{
            justify-content: center;
        }
        
        .toogleWrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 8px 0px;
            min-height: 32px;
            color: ${({ theme }) => theme.network_map_text};
        }
        
        .modalFooterInstanceVerification{
            border-top: unset;
            justify-content: center;
        }
        #FooterModalInstanceDetails{
            justify-content: center !important;
        }
        .rightPanelHeaderModal{
            padding-left: 8px;
            width:100%;
            margin-top: 6px;
        }
        .rightPanelHeaderInstaceModal{
            flex: 1;
            display: flex;
            //align-items: center;
            border-right: 2px solid rgba(0,0,0,.1);
            margin-right: 20px;
            min-height: 160px;
            border-image: linear-gradient(190deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.1),rgba(0,0,0,.2),hsla(0,0%,100%,.1),hsla(0,0%,100%,0)) 1 100%;
        }
        .slidersContainer{
            flex: 1;
            min-height: 160px;
        }
        .leftPanelHeaderModal{
            flex: 1;
            margin-right: 20px;
            min-height: 160px;
            border-right: 2px solid rgba(0,0,0,.1);
            border-image: linear-gradient(190deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.1),rgba(0,0,0,.2),hsla(0,0%,100%,.1),hsla(0,0%,100%,0)) 1 100%;
        }
        .checkboxModal{
            margin: unset!important;
            position: static;
        }
        .titleOfInput{
            text-align: start;
            display: inline-block;
            width: 84px;
            color: ${({ theme }) => theme.network_map_text};
            margin-right: 6px;
            user-select: none;
        }
        .storageSpan{
            
            min-height: 32px;
            
            display: flex;
            
            align-items: center;
            
        }
        .textBoxLineWrapper{
            display: flex;
            align-items: center;
            margin: 8px 0px;
            min-height: 32px;
        }
        .editDivider{
            width: 1px;
            display: inline-block;
            height: 12px;
            background-color: grey;
            margin: 0px 8px;
        }
        
        .playeysForms, .adminTextboxes{
            display: flex;
        }
        .adminTextboxes{
            margin-top: 6px;
            display: flex;
        }
        
        .adminTextboxes>span {
            flex: 1;
        }
        .inputInstanceModal{
            /*width: 90%;*/
        }
        .inputInstanceModal:focus{
            //border: 1px solid rgba(89,89,89,0.25);
            //box-shadow: unset;
        }
        
        .test{
            //background-color: black!important;
        }
        
        .inputUpperPanel{
            margin-right: -2px;
            padding: 0px;
            background-color: ${({ theme }) => theme.network_map_input};
            color: ${({ theme }) => theme.network_map_text} !important;
        }
        
        
        .inputUpperPanel:focus{
            outline-color: rgba(89,89,89,0.35);
            /*border: 1px solid rgba(89,89,89,0.25) !important;*/
            /*box-shadow: 0px 0px 5px rgba(89, 89, 89, 0.35);*/
        }
        
        .deleteSwitchTrash{
            right: 16px;
            position: absolute;
            display: flex;
            justify-content: space-between;
        }
        .deleteSpan{
            margin: 0px 8px;
        }
        .instancePaintModal{
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        .playerForm{
            flex: 1;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
            margin-top: 8px;
            margin-right: 10px;
        }
        .marginRightUnset{
            margin-right: unset;
        }
        .marginLeftUnset{
            padding-left: unset!important;
        }
        .collapseForms{
            text-align: start;
            margin-bottom:16px;
        }
        
        #modalInstaceDetails, #modalSubentDetails{
            margin-top: 200px;
        }
        .alignItems{
            display: flex;
            align-items: center;
        }
        .boldModal{
            color: ${({ theme }) => theme.network_map_text};
            font-weight: 400;
        }

        .instanceModalTitleEdit{
            
            text-align: start;
        }
        .detailsTitleVmModal{
            color: ${({ theme }) => theme.network_map_text};
            text-align: start;
            font-weight: 500;
            //margin-top:6px;
        }
        .marginLeft10{
            margin-left: 10px;
        }
        .titleInstanceModal{
            
        }
        .sliderWrapperModal{
            margin: 0 auto;
            height: 35px;
        }
        .sizeDetailsInstanceModal{
            font-size: 12px;
            text-align: start;
        }
        .editTextboxBtn{
            color: #7F7F7F;
        }


        .startup-script-title {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }

        .startup-script-description {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            //color: ${({ theme }) => theme.startup_script_text_color}; 
        }

        /* . {
            font-family: 'Courier New', monospace !important;
            color: ${({ theme }) => theme.startup_script_text_color}; 
            border: 1px solid ${({ theme }) => theme.attack_scenario_border};
            line-height: 16px;
            font-size: 14px;
        } */

        .startup-script-textarea:focus {
            font-family: 'Courier New', monospace !important;
            color: ${({ theme }) => theme.startup_script_text_color}; 
            border: 1px solid ${({ theme }) =>
              theme.startup_script_border_color};
            line-height: 16px;
            font-size: 14px;
        }
        
        /*end intance modal details*/




        /*subnet details modal*/
        .leftHeaderModalSubnet{
            flex: 1;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 12px;
            border-right: 2px solid rgba(0,0,0,.1);
            border-image: linear-gradient(190deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.1),rgba(0,0,0,.2),hsla(0,0%,100%,.1),hsla(0,0%,100%,0)) 1 100%;
        margin-right: 16px;
        
        }
        .rightLastSectionSubnetModal{
            flex: 1;
            display: flex;
            align-items: center;
        }
        .rightHeaderAndDivider{
            flex: 1.5;
        }
        .connectedIpDDLSubnetModal{
            text-align: start;
        }
        
        .rightHeader{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 12px;
            text-align: start;
            padding-left: 8px;
            
        }
        .cidrWrapper{
            display: flex;
            align-items: center;
        }
        .radioCircleModal{
        padding: 0px 9px 0px 0px !important;
        }
        .inputAndEditSubnetModal{
        display: flex;
        align-items: center;
        }
        .shotDownAndSwichModal{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .deleteAndTrashSubent{
            position: absolute;
            right: 16px;
            display: flex;
            align-items: center;
            
        }
        .errMsg{
            color:red;
            font-size: 12px;
            margin-top: -10px;
            margin-bottom: -5px;
            height: 20px;
        }
        
        
        .margin4{
            margin: 4px;
        }
        .subnetModalInput{
        border: none;
        outline: unset;
        background-color: ${({ theme }) => theme.network_map_input};
        color: ${({ theme }) => theme.network_map_text};
        }
        .cidrSpan{margin-right: 4px;}
        .marginRight80{
            margin-right: 80px;
        }
        .marginRight20{
        margin-right: 20px !important;
        }
            
            .dividerModal20{
            background-color: rgba(151,151,151,0.65);
            width:1px;
            height: 120px;
            }
            .disabledOkBtnModal{
                opacity: 0.4;
            }
            .nameAndLogo{
                display: flex;
                flex-direction:column ;
                align-items: center;
                margin-top: 5px;
            }
            .instanceTitleInsideSubnet{
                font-size: 8px;
                margin:2px 4px;
                height:12px;
                text-align:center;
                white-space: nowrap; 
                width: 90%; 
                overflow: hidden;
                text-overflow: ellipsis; 
            }
            .spanBeforeInput{
        
                /*padding: 4px;*/
                display: flex;
                align-items: center;
                min-height: 32px;
                box-sizing: border-box;
            }
            .slidersWrapperTitle{
                text-align: start;
                width: 130px;
                color: ${({ theme }) => theme.network_map_text};
            }
            .titleRightPanelInstance{
                width: 80px;
                text-align: start;
            }
            .sliderNumberModal{
                width: 30px;
            }
            .storageInput{
                max-width: 30px !important;
                margin-right: -5px;
            }
            .plusMinusButton{
                border-radius: 50%;
                color: #fff;
                background-color: #49bd95;
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin: 0px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .plusIcon{
                border-radius: 50%;
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #595959;
                margin: 0 auto;
                margin-top: 16px;
                margin-bottom: 16px;
                cursor: pointer;
                border: 1px solid #595959;
                user-select: none;
            }
            .specificDDL{
                display: inline-block;
                min-width: 145px;
            }
        
            .ruleLine{
                border: dashed 1px grey;
                box-sizing: border-box;
                margin: 11px 25px;
                margin-bottom: 5px;
                padding: 8px;
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                gap: 5px;
                position: relative;
                border-radius: 2px;
            }
            .minusEditSubnetModal{
                position: absolute;
                top: 0px;
                left: -28px;
                font-size: 20px;
                padding-bottom: 2px;
            }
            .ddlToggleBorder:focus{
        box-shadow: none;
            }
            .greyBorder{
                border: 1px solid grey;
                border-radius: 4px;
            }
            .specificTitle{
                font-weight: bold;
                width: 125px;
            }
        
            .titlesLine{
            padding: 8px;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 5px;
            gap: 5px;
            margin: 11px 25px;
            }
            .ddlRuleSubnetModal{
                max-width: 125px;
            }
            .specificTitleTextboxModal{
                min-width: 145px;
            }
            .minusSymbol{
                margin: 5px;
            }
            .srcPortModal{
                display: flex;
            }
        
            .rulesList{
            padding: 2px;
            max-height: 270px;
            
            }
            
            .rulesList::-webkit-scrollbar {
            width: 3px;
            }
            
            /* Track */
            .rulesList::-webkit-scrollbar-track {
            background: #f1f1f1; 
            }
            
            /* Handle */
            .rulesList::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 15px; 
            }
            
            /* Handle on hover */
            .rulesList::-webkit-scrollbar-thumb:hover {
            background: #555; 
            }
            .disableRuleModal{
                opacity: 0.5;
                cursor:not-allowed ;
            }
            .dropdown-toggle.btn.disabled{
            cursor:not-allowed ;
            }
            
        /*end subnet details modal*/
        .disableClick{
            cursor: not-allowed;
        }
        .imageAmiWrapper{
            margin-top: 30px;
        }
        /*Create Network Modal:*/
        
        .createNetworkModalTitle{
            margin: 0 auto;
        }
        
        .createNetworkModalTitleSpan{
            padding: 3px;
        }
        .labelCreateNetworkModal{
            width: 50px;
            display: inline-block;
            text-align: start;
            margin-right: 20px;
        }
        .cidrTitleCreateNetworkModal{
            font-weight: bold;
            margin-right: 4px;
        }
        /*End Create Network Modal:*/
        /*Update Network Modal:*/
        .headerModalUpdateNetwork{
            position: relative;
        }
        .deleteAndTrashUpdateModal{
            position: absolute;
            top: 24px;
            right: 0px;
            font-weight: 300;
            display: flex;
            align-items: center;
        }
        .updateNetworkModalFooter{
            display: flex;
            justify-content: center;
        }
        .headerModalUpdateNetwork{
            display: block;
            padding: 24px;
        }
        .cidrWrapperUpdateNetworkModal{
            display: flex;
            align-items: center;
            margin-bottom: 0;
            
        }
        .marginRightUnset{margin-right: unset;}
        .updateCreateNetworkModalFooter{
            justify-content: center;
        }
        .updateNetworkBtnModal{
            border: dashed 1px grey;
            width: 170px;
            height: 24px;
            border-radius: 4px;
            margin: 0 auto;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            color: grey;
        }
        
        
        .updateNetworkBtnWrapper{
            padding-bottom:40px;
            position: relative;
        }

        .marginRight5{
            margin-right: -5px;
        }
        .connectedHirarchy{
            margin-left: 8px;
            width: 17px;
            opacity: 1.0;

            opacity: 1;
            display: block;
            background: url(${({ theme }) => theme.network_icon}) no-repeat;
            width: 16px;
            height: 16px;
            border-width: thin;
            outline: unset;


        }
        .marginRight7{
            margin: 0px 7px;
        }
        /*
        verification networkk modal
        */
        
        /*
        END verification networkk modal
        */
        
        
        /*END Update Network Modal:*/
        @media screen and (max-width: 1800px) {
            #modalInstaceDetails,#modalSubentDetails{
                margin-top: 90px !important;
            }    
            .modal-dialog.modal-xl{
               /* max-width: 60%;*/
            }   
        }
        .marginRight10{
            margin-right: 10px;
        }
        .alignCenterFlex{
            display: flex;
            align-items: center;
        }
        .borderRdius4{
            border-radius: 4px;
            border: 1px grey solid;
        }
        .borderRdius4:focus{
            border-radius: 4px;
            outline: grey;
        }
        /*drop down css*/
        .ctypeInstanceModal{
            min-width: 150px;
            outline: unset;
        }
        .ctypeInstanceModal{
           text-align: start ;
        }
        .connectSubnetModal{
            max-width: 265px;
            text-align: start !important;
        }
        
        /*end drop down css */
        
        


        .network-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: right;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .network-button:active{
            background-color: ${({ theme }) => theme.button};   
        }
        .network-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .network-button:not(:disabled):not(.disabled):active:focus, .show > .network-button.dropdown-toggle:focus {
            box-shadow: unset;
        }


        







        /* Main Dashboard */

        .map-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 65vh;
            margin: 16px;

            :focus {
                outline: unset !important;
            }
        }
        
        .mapboxgl-canvas:focus {
            outline: unset !important;
        }



        
        
        #marker {
            background-image: url(${logo_mask});
            background-size: cover;
            width: 20px;
            height: 30px;
            //border-radius: 50%;
            cursor: pointer;
            position: absolute;
            top: 0;
           
        }
        
        .mapboxgl-popup {
            position: absolute;
            top: 0;
            width: 200px;
            //height: 100px;
            
        }
        .mapboxgl-popup-content {
            background-color: rgba(0,0,0, 0.7);
            color: rgba(white, 0.5);
            font-size: 10px;
            padding: 10px;
            border-radius: 0.5em;

            .red {
                padding: 5px;
                border-left: 2px solid ${({ theme }) => theme.red_tabs_border};
            }
            .blue {
                padding: 5px;
                border-left: 2px solid ${({ theme }) => theme.blue_tabs_border};
            }
        }

        .mapboxgl-popup-close-button {
            background-color: white;
            color: black;
            border: unset;
        }
        
        


        .reset-map-button{
            display: block;
            position: relative;
            margin-top: 32px;
            margin-left: 16px;
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
            z-index: 1;
        }
    
        .reset-map-button:active{
            background-color: ${({ theme }) => theme.button};
        }
        .reset-map-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .reset-map-button:not(:disabled):not(.disabled):active:focus, .show > .reset-map-button.dropdown-toggle:focus {
            box-shadow: unset;
        }





        /* MAPBOX CREDITS COLORS */
        .mapboxgl-ctrl-attrib-inner a {
            color: ${({ theme }) => theme.background} !important;
        
        }
        
        



        /* Event Dashboard */

        .white-border {
            border-left-color: ${({ theme }) =>
              theme.event_dashboard_white_border} !important;
        }
        .grey-border {
            border-left-color: ${({ theme }) =>
              theme.event_dashboard_grey_border} !important;
        }
        .red-border {
            border-left-color: ${({ theme }) =>
              theme.event_dashboard_red_border} !important;
        }
        .blue-border {
            border-left-color: ${({ theme }) =>
              theme.event_dashboard_blue_border} !important;
        }
        .green-border {
            border-left-color: ${({ theme }) =>
              theme.event_dashboard_green_border} !important;
        }

        .event-dashboard-summary-header-title {
            font-size: 12px;
        }
        .event-dashboard-summary-header-text {
            font-size: 24px;
        }
        .event-dashboard-completed {
            margin-bottom: 0px;
            font-size: 24px;
            color: ${({ theme }) => theme.event_dashboard_completed_text};
            font-weight: bold;
        }
        .event-dashboard-divider {
            margin-top: 16px;
            margin-bottom: 16px;
            border-top: 1px solid ${({ theme }) =>
              theme.event_dashboard_divider};
        }
        .event-dashboard-card-divider {
            margin-top: 8px;
            margin-bottom: 8px;
            border-top: 1px solid ${({ theme }) =>
              theme.event_dashboard_card_divider};
        }
        .event-dashboard-card {
            margin-top: 20px;
            border: 1px solid ${({ theme }) =>
              theme.event_dashboard_card_border};
            border-radius: 1em;

            .card-header {
                background-color: unset;
                border-color: ${({ theme }) =>
                  theme.event_dashboard_card_border};
            }
            .event-dashboard-card-row {
                padding-bottom: 5px;
            }
            .event-dashboard-card-header {
                font-size: 18px;
            }
            .event-dashboard-card-title {
                font-size: 12px;
            }
            .event-dashboard-card-text {
                font-size: 12px;
            }
            .bold {
                font-weight: bold;
            }
        }

        .event-dashboard-buttons {
            padding-top: 10px;
        }
        .event-dashboard-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: left;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .event-dashboard-button:active{
            background-color: ${({ theme }) => theme.button};
        }
        .event-dashboard-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .event-dashboard-button:not(:disabled):not(.disabled):active:focus, .show > .event-dashboard-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
        .event-dashboard-hints-modal-description{
            p{
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .event-team-button-table{
            width: 40%;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_update};
            border:  1px solid ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            border-radius: 0.6em;
            font-weight: 500;
            //float: left;
            font-size: 10px;
            padding: unset;
            margin-right: 8px;
        }

        .event-team-button-table:focus{
            outline: unset;
        }
        .event-team-button-table:active{
            background-color: ${({ theme }) => theme.btn_update};   
            outline: unset;
        }
        .event-team-button-table:hover{
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
        }
        .event-team-button-table:not(:disabled):not(.disabled):active:focus, .show > .event-team-button-table.dropdown-toggle:focus {
            box-shadow: unset;
        }

        .event-team-button-table:disabled {
            border-color: ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            opacity: 0.20;
        }



        


        .event-team-danger-button-table{
            width: 40%;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_danger};
            border:  1px solid ${({ theme }) => theme.btn_danger_border};
            color: ${({ theme }) => theme.btn_danger_text};
            border-radius: 0.6em;
            font-weight: 500;
            //float: left;
            font-size: 10px;
            padding: unset;
            margin-right: 8px;
        }

        .event-team-danger-button-table:focus{
            outline: unset;
        }
        .event-team-danger-button-table:active{
            background-color: ${({ theme }) => theme.btn_danger};   
            outline: unset;
        }
        .event-team-danger-button-table:hover{
            border-color: ${({ theme }) => theme.btn_danger_hover};
            color: ${({ theme }) => theme.btn_danger_hover};
        }
        .event-team-danger-button-table:not(:disabled):not(.disabled):active:focus, .show > .event-team-danger-button-table.dropdown-toggle:focus {
            box-shadow: unset;
        }

        .event-team-danger-button-table:disabled {
            border-color: ${({ theme }) => theme.btn_danger_border};
            color: ${({ theme }) => theme.btn_danger_text};
            opacity: 0.20;
        }






        .team-unregister-button-table{
            width: 100%;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_update};
            border:  1px solid ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            border-radius: 0.6em;
            font-weight: 500;
            //float: left;
            font-size: 10px;
            padding: unset;
            margin-right: 8px;
        }

        .team-unregister-button-table:focus{
            outline: unset;
        }
        .team-unregister-button-table:active{
            background-color: ${({ theme }) => theme.btn_update};   
            outline: unset;
        }
        .team-unregister-button-table:hover{
            border-color: ${({ theme }) => theme.btn_update_hover};
            color: ${({ theme }) => theme.btn_update_hover};
        }
        .team-unregister-button-table:not(:disabled):not(.disabled):active:focus, .show > .team-unregister-button-table.dropdown-toggle:focus {
            box-shadow: unset;
        }

        .team-unregister-button-table:disabled {
            border-color: ${({ theme }) => theme.btn_update_border};
            color: ${({ theme }) => theme.btn_update_text};
            opacity: 0.20;
        }





        .global-toaster {
            width: 600px;
        }

        .toast {
            color: ${({ theme }) =>
              theme.event_dashboard_white_text} !important;
            border: 1px solid ${({ theme }) =>
              theme.event_dashboard_grey_border};
            border-radius: 0.35rem;
            margin-bottom: 100px;

            .toast-header {
                background-color: ${({ theme }) =>
                  theme.event_dashboard_grey_background};
                border-bottom: unset;
                color: ${({ theme }) => theme.event_dashboard_white_text};
                font-weight: 600;
            }
        
            .toast-body {
                background-color: ${({ theme }) =>
                  theme.event_dashboard_black_background};
                border: 1px solid ${({ theme }) =>
                  theme.event_dashboard_grey_background};
                color: ${({ theme }) => theme.event_dashboard_white_text};
            }
        
            .close.ml-auto {
                color: ${({ theme }) => theme.event_dashboard_white_text};
            }
        }


        .connect-link {
            cursor: pointer;
        }





        /* ATTACK SCENARIO */


        .attackDDLModal{
            margin-bottom: 24px;
            margin-top: 16px;
        }

        .attack-scenario-phase-modal-body {
            max-height: 492px;
            .col-md-6 {
                padding-left: 0px;
            }

            .form-group {
                align-items: center;
            }
            label {
                display: inline-block;
                margin-bottom: 0rem;
            }
        }

   
        .disableBtnAttacker{
            cursor: not-allowed;
        }


        .axis{
            padding-top: 200px;
            border-radius: 1em;
            width: 98%;
            //margin: 60px;
            position: relative;
            //top: 280px;
            margin-left: 10px;
            
            
        
            display: flex;
            justify-content: space-between;
        }
        
        .specificTarget{
            min-height: 62px;
            border-right: 2px solid rgba(0,0,0,.8);
            background: ${({ theme }) => theme.attack_scenario_timeline};
            display: flex;
            //padding: 4px 4px;
            margin-top: 2px;
            margin-right: 2px;
            position: relative;
            border: 1px solid ${({ theme }) =>
              theme.attack_scenario_timeline_border};
        &.firstTarget{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }  
        &.lastTarget{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            //border-right:unset;
        } 
        .phaseTarget.lastTarget{
            border-right:unset;
            margin-right: -2px;
        } 
        }
        .targetAndTitle{
            display: flex;
            flex-direction: column;
            width: 100%;
            min-height: 60px;
            user-select: none;
            position: relative;
        }
        .phaseTarget{
            margin-top: 6px;
            margin-bottom: 6px;
            margin-left: 10px;
            position: relative;
        }

        .phase-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: right;
            font-size: 11px;
        }
    
        .phase-button:active{
            background-color: ${({ theme }) => theme.button};   
        }
        .phase-button:hover{
            //background-color: ${({ theme }) => theme.button_hover};
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .phase-button:disabled{
            border-color: ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
        }
        .phase-button:not(:disabled):not(.disabled):active:focus, .show > .phase-button.dropdown-toggle:focus {
            box-shadow: unset;
        }



        .bottomTitleAxis{
            width: 100px;
            height: 25px;
            font-weight: bold;
            //z-index: 99999;
            margin-bottom: 5px;
            margin-top: 5px;
            text-align: center;
        }
        
        .targetTitleAttacker{
            height: 25px;
            width: 150px;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 
            margin: 0 auto;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 5px;
            text-align: center;
        }
        .bottomTitleAxisEnd{
            text-align: end;
            font-weight: bold;
            margin-top: 10px;
        }
        .bottomLineAttacker{
            border-left: 2px solid ${({ theme }) =>
              theme.attack_scenario_side_border};
            border-right: 2px solid ${({ theme }) =>
              theme.attack_scenario_side_border};
            //border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) 8 100%;
        }
        
        /*header Attacker Verification Phase Modal*/
        #verificationPhaseModalAttacker{
            padding: 0px 16px;
        }
        .yesBtnModalAttacker{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 30px;
            color: #fff;
            width: 90px;
            font-size: 12px;
            height: 24px;
            background: #49bd95;
            border-radius: 4px;
        }
        
        .cancelBtnModalAttacker{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 30px;
            //color: ${({ theme }) => theme.attack_scenario_modal_cancel_x};
            width: 90px;
            font-size: 12px;
            font-weight: bold;
            height: 24px;
            border-radius: 4px;
        }
        
        #headerAttackerVerificationPhaseModal{
            justify-content: space-between;
            font-size: 15px;
        }
            
        /*header Attacker Verification Phase Modal END*/
        
        @keyframes right-to-left {
            0% {
                transform: translate(750px, 0px);
                opacity: 0;
        
            }
        
            100% {
                transform: translate(0px, 0px);
                opacity: 1;
                visibility: visible;
            }
        }
        .phaseDeatilsAnimate{
            animation-name: right-to-left;
        }
        .attackDetailsMarque{
            padding-right: 10px;
            padding-left: 10px;
            animation-name: right-to-left;
            animation-duration: 2s;
            border-left: 2px solid ${({ theme }) =>
              theme.attack_scenario_side_border};
            //border-right: 2px solid rgba(0, 0, 0, 0.1);
            //border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) 1 100%;
            
            width: max-content;
            margin: 0 auto;
            font-weight: bold;
            //padding-top: 16px;
            position: absolute;
        }
        
        .phaseNameTimeLine{
            position: absolute;
            left: 0;
            width: 100px;
            white-space: nowrap; 
            transform: rotate(-80deg);
            transform-origin: top left;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        #imgPhaseTimeLine{
            width: 100%;
            height: 100%;   
        }
        
        .attacksNamesOfTarget{
            
            top: 120px;
            color: ${({ theme }) => theme.attack_scenario_target_phases_text};
            display: flex;
            text-align: start;
            flex-direction: column;
            width: 95%;
            border: 1px solid ${({ theme }) =>
              theme.attack_scenario_target_phases_border};
            border-radius: 0.5em;
            //box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
            transition: all .4s ease-in-out;
            margin: 0 auto;
            margin-top: 40px;
            padding: 10px;
        
         
        }
        .attackNameInsideTarget{
            padding-left: 4px;
        }
        
        .attacksNamesOfTarget.lastAttacks{
            margin-top: 19px;
        }
        .phaseToShowAttacker{
            max-height: 250px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 11;
        }

        


        // READINESS DASHBOARD

        /* Players Dashboard */

        .players_summary_table_container {
            overflow-y: auto;

            //height: 400px;
            ::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px;
                //border: 2px solid white; /* should match background, can't be transparent */
                background-color: rgba(white, 0.2);
            }

            .players_summary_table {
                
                //width: 600px;
                margin-left: 40px;
                margin-top: 60px;
                margin-bottom: 0px;
        
                thead {
                    width: calc( 600px - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
            
                }
                tbody {
                    display:block;
                    height:410px;
                    //width: 800px;
                    overflow:auto;
                }
                thead, tbody tr {
                    display:table;
                    width:100%;
                    table-layout:fixed;/* even columns width , fix width of table too*/
                }
        
                .username {
                    cursor: pointer;
                    width:200px;
                    //overflow: hidden;
                    white-space: nowrap;
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_username_background};
                    border-right: 3px solid ${({ theme }) => theme.background};
                    border-bottom: 3px solid ${({ theme }) => theme.background};
                }
        
                .global-score {
                    font-size: 14px;
                    font-weight: 600;
                    width: 40px;
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_score_background};
                    color: rgb(73,189,149);
                    border-right: 3px solid ${({ theme }) => theme.background};
                    border-bottom: 3px solid ${({ theme }) => theme.background};
                }
        
                .topic-score {
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                    height: 6px;
                    margin-top: 4px;
                }
        
                .black {
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_topic_black};
                }
                .red {
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_topic_red};
                }
                .orange {
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_topic_orange};
                }
                .yellow {
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_topic_yellow};
                }
                .green {
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_topic_green};
                }        
        
                border-collapse: collapse;
        
                th {
                  padding-top: 0px;
                  padding-left: 0px;
                }
                td {
                    width: 44px; // must be same as th.rotate width
                  text-align: center;
                  //padding: 5px;
                  border-top: 1px solid ${({ theme }) => theme.table_row};
                  border-right: 1px solid ${({ theme }) => theme.table_row};
                  border-bottom: 3px solid ${({ theme }) => theme.table_row};
                }
        
                th.rotate {
                  height: 140px;
                  width: 44px; // must be same as td width
                  white-space: nowrap;
                  // Firefox needs the extra DIV for some reason, otherwise the text disappears if you rotate 
                  > div {
                    transform: 
                      // Magic Numbers
                      translate(32px, 51px)
                      // 45 is really 360-45
                      rotate(315deg);
                      //rotate(285deg);
                    width: 20px;
                  }
                  > div > span {
                    border-bottom: 1px solid ${({ theme }) => theme.table_row};
                    padding: 5px 10px;
                    width: 100%;
                  }
                }
                th.row-header {
                  //padding: 0 10px;
                  border-bottom: 1px solid ${({ theme }) => theme.table_row};
                }      
        
                .score-tooltip{
                    position:relative;
                }
                  
                .score-tooltip-data{
                    display:none;
                    position:absolute; 
                    z-index:100;
                    border:1px;
                    background-color: ${({ theme }) => theme.background};
                    border-style:solid;
                    border-width:1px;
                    border-color: ${({ theme }) => theme.table_row};
                    padding:3px;
                    color: ${({ theme }) => theme.table_text}; 
                    top:30px; 
                    left:10px;
                }
                
                .score-tooltip:hover span.score-tooltip-data{
                    display:block;
                }
        
                .name-tooltip{
                    position:relative;
                }
                  
                .name-tooltip-data{
                    display:none;
                    position:absolute; 
                    z-index:100;
                    border:1px;
                    background-color: ${({ theme }) => theme.background};
                    border-style:solid;
                    border-width:1px;
                    border-color: ${({ theme }) => theme.table_row};
                    padding:3px;
                    color: ${({ theme }) => theme.table_text}; 
                    top:30px; 
                    left:10px;
                }
                
                .name-tooltip:hover span.name-tooltip-data{
                    display:block;
                }
            }            

        }





        .readiness-container {
            //padding-top: 10px;
        }

        .score-change-red {
            color: ${({ theme }) => theme.score_change_red};
        }

        .score-change-green {
            color: ${({ theme }) => theme.score_change_green};
        }

        .last-month-score {
            font-size: 11px;
        }



        .shadow-box {
            background-color: ${({ theme }) =>
              theme.players_dashboard_readiness_background};
            //width:100%;
            height: 100%;
            ${({ theme }) => theme.players_dashboard_readiness_shadow};
            border-radius: 10px;
            padding: 20px;
        }


        // badge

        .score-badge {
            height: 200px;
            width: 150px;
            text-align: center;
            //padding-top: 10px;
            //padding-bottom: 10px;
            margin: 0px auto;

            .badge-header {
                width: 100%;
                height: 30%;
                background-color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_header};
                border-top-right-radius: 0.5em;
                border-top-left-radius: 0.5em;
                //padding-top: 10px;
                ${({ theme }) => theme.players_dashboard_score_badge_shadow}
            }

            .badge-body {
                width: 100%;
                height: 10%;
                background-color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_body};
                font-family: 'Orbitron', sans-serif;
                font-size: 0.7em;
                font-weight: 600;
                line-height: 10%;
                padding-top: 10px;
                text-align: center;
                color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_text};
                ${({ theme }) => theme.players_dashboard_score_badge_shadow}
            }

            .badge-rank-body {
                width: 100%;
                height: 10%;
                background-color: ${({ theme }) => theme.rank_badge_body};
                font-family: 'Orbitron', sans-serif;
                font-size: 0.7em;
                font-weight: bold;
                line-height: 10%;
                padding-top: 10px;
                text-align: center;
                color: ${({ theme }) => theme.rank_badge_text};
                ${({ theme }) => theme.rank_badge_shadow}
            }

            .badge-footer {
                width: 100%;
                height: 60%;
                background-color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_footer};
                border-bottom-right-radius: 45%;
                border-bottom-left-radius: 45%;
                ${({ theme }) => theme.players_dashboard_score_badge_shadow}
            }
            .badge-logo {
                margin: 20px;
                width: 35%;
                opacity: 0.8;
            }

            .badge-rank-logo {
                padding-top: 8px;
                width: 20%;
                opacity: 0.75;
            }

            .badge-rank {
                margin-top: 16px;
                width: 60%;
                opacity: 0.6;
            }

            span {
                font-family: 'Orbitron', sans-serif;
                font-size: 2.6em;
                //margin-top: 10px;
                font-weight: 600;
                color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_text};
            }
        }

          
        /* LEADERBOARD */

        .campaign-name-header {
            padding-bottom: 8px;
        }

        .carousel .slide {
            height: 240px;
            background: unset;
        }


        .legend {
            display: none;
        }



        .leaderboard-team-wrapper {
            padding-top: 20px;
        }
        .leaderboard-team-grid {
            width: 98%;
            height: 130px;
            //float: right;
            z-index: 0;
            position: absolute;
            margin-top: 20px;
            margin-left: 0px;
            border-radius: 0.5em;
            ${({ theme }) => theme.leaderboard_team_grid}
        }

        .leaderboard-team-grid-content {
            padding-left: 180px;
            //line-height: 130px;
            text-align: left;
            //width: 98%;
            
            .team-name {
                font-family: 'Orbitron', sans-serif;
                font-size: 2.0em;
                font-weight: 600;
                color: ${({ theme }) =>
                  theme.leaderboard_team_grid_content_name};
            }

            .team-info {
                font-family: 'Orbitron', sans-serif;
                font-size: 0.9em;
                font-weight: 600;
                color: ${({ theme }) =>
                  theme.leaderboard_team_grid_content_name};
                padding-left: 0px;
                padding-top: 0px;
            }

            .team-info-hline {
                margin-top: 6px;
                margin-bottom: 6px;
                border-bottom: 1px solid transparent;
                border-image: 
                linear-gradient(
                  to right, 
                  rgba(0,0,0, 0),
                  rgba(255,255,255, 1.2),
                  rgba(0,0,0, 0) 
                ) 1;
            }

            .team-info-divider {
                margin-right: 5px;
                margin-left: 5px;
            }

            .team-info-text {
                font-family: 'Orbitron', sans-serif;
                font-size: 0.7em;
                color: ${({ theme }) =>
                  theme.leaderboard_team_grid_content_name};
                padding-left: 0px;
                padding-top: 0px;
            }


            .team-points {
                font-family: 'Orbitron', sans-serif;
                font-size: 2.2em;
                font-weight: 600;
                line-height: 130px;
                color: ${({ theme }) =>
                  theme.leaderboard_team_grid_content_name};
                padding-left: 80px;

                .pos-icon {
                    width: 2.2em;
                    margin-left: 30px;
                    vertical-align: middle;
                }

               

            }

            .team-chart {
                padding: 16px;
                width: 80%;
            }

        }

        .c-avatar-img-leaderboard-team {
            padding: 10px;
            width: 100%;
            height: 120px;
            border-bottom-right-radius: 45%;
            border-bottom-left-radius: 45%;
        }

        .c-avatar-img-leaderboard-team-pos {
            padding: 6px;
            width: 100%;
            height: 84px;
            border-bottom-right-radius: 45%;
            border-bottom-left-radius: 45%;
        }



        .leaderboard-team-pos-grid-content {
            padding-left: 2.5em;
            line-height: 90px;
            text-align: left;
            //width: 100%;
            
            .team-name {
                font-family: 'Orbitron', sans-serif;
                font-size: 1.8em;
                font-weight: 600;
                color: ${({ theme }) =>
                  theme.leaderboard_team_grid_content_name};
            }
            .team-points {
                font-family: 'Orbitron', sans-serif;
                font-size: 1.5em;
                font-weight: 600;
                color: ${({ theme }) =>
                  theme.leaderboard_team_grid_content_name};
                padding-left: 3.8em;

                .pos-icon {
                    width: 2.4em;
                    margin-left: 2.0em;
                    vertical-align: middle;
                }

                .points {
                    padding-left: 1.0em;
                }

            }

            .team-chart {
                padding: 0.5em;
                width: 80%;
            }
        }


        .leaderboard-team-grid-place {
            width: 90%;
            height: 90px;
            background-color: rgba(255,255,255, 0.03);
            margin-left: 0px;
            //float: right;
            z-index: 0;
            position: absolute;
            margin-top: 16px;
            margin-left: 120px;
            border-radius: 0.5em;
            ${({ theme }) => theme.leaderboard_team_grid}
        }

        .leaderboard-score-badge {
            position: absolute;
            z-index: 10;
            height: 200px;
            width: 150px;
            text-align: center;
            //padding-top: 10px;
            //padding-bottom: 10px;
            //margin: 0px auto;
            
            .badge-header {
                width: 100%;
                height: 30%;
                background-color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_header};
                border-top-right-radius: 0.5em;
                border-top-left-radius: 0.5em;
                padding-top: 6px;
                ${({ theme }) => theme.players_dashboard_score_badge_shadow}  
            }

            .badge-body {
                width: 100%;
                height: 10%;
                background-color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_body};
                font-family: 'Orbitron', sans-serif;
                font-size: 0.7em;
                font-weight: 600;
                line-height: 10%;
                padding-top: 0.8em;
                text-align: center;
                color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_text};
                ${({ theme }) => theme.players_dashboard_score_badge_shadow}
            }


            .badge-footer {
                width: 100%;
                height: 60%;
                background-color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_footer};
                border-bottom-right-radius: 45%;
                border-bottom-left-radius: 45%;
                ${({ theme }) => theme.players_dashboard_score_badge_shadow}
            }
            .badge-logo {
                //width: 35%;
                opacity: 0.8;
                border-radius: unset;
            }


            .badge-rank {
                margin-top: 16px;
                width: 60%;
                opacity: 0.6;
            }

            span {
                font-family: 'Orbitron', sans-serif;
                font-size: 2.3em;
                font-weight: 600;
                color: ${({ theme }) =>
                  theme.players_dashboard_score_badge_text};
            }

            .place-text {
                font-size: 1.5em;
            }
            .place-team {
                font-size: 0.1em !important;
                padding-top: 8px;
            }
        }



        .leaderboard-first-place {
            position: relative;
            height: 150px;        
            margin-top: 8px;    

            .place {
                height: 140px !important;
                width: 100px !important;
                margin-left: 24px;
            }

        }
        .leaderboard-second-place {
            position: relative;
            height: 150px; 
            margin-top: 8px;    
            
            .place {
                height: 140px !important;
                width: 100px !important;
                margin-left: 24px;
            }
        }
        .leaderboard-third-place {
            position: relative;
            height: 150px; 
            margin-top: 8px;  

            .place {
                height: 140px !important;
                width: 100px !important;
                margin-left: 24px;
            }
        }





        // USER REPORT

        .strong-topic {
            color: ${({ theme }) => theme.players_dashboard_topic_green};
        }
        .weak-topic {
            color: ${({ theme }) => theme.players_dashboard_topic_red};
        }

        .marker {
            white-space: nowrap;
            font-size: 8px;
            margin-top: 6px;
        }


        .dynamic-topics {
            height: 210px;
            overflow-y: scroll;
            margin-left: 12px;

        }

        .topic-score {
            overflow: hidden;
            white-space: nowrap;
            width: 16px;
            height: 16px;
            margin-top: 4px;
        }

        .black-topic {
            background-color: ${({ theme }) =>
              theme.players_dashboard_topic_black};
        }
        .red-topic {
            background-color: ${({ theme }) =>
              theme.players_dashboard_topic_red};
        }
        .orange-topic {
            background-color: ${({ theme }) =>
              theme.players_dashboard_topic_orange};
        }
        .yellow-topic {
            background-color: ${({ theme }) =>
              theme.players_dashboard_topic_yellow};
        }
        .green-topic {
            background-color: ${({ theme }) =>
              theme.players_dashboard_topic_green};
        }  

        .skill-small {
            font-size: 10px;
        }





        /*
         *
         * Player UI
         *  
        */

        .campaign-video-container {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: black;
            width: 100%;
            height: 100%;
            object-fit: fill !important;
            opacity: 1;

            .campaign-video {
                position: relative;
                width: 100%;
                height: 100%;

                video {
                    object-fit: inherit !important;
                    width: 768px !important;
                    height: 432px !important;
                    position: absolute !important;
                    top: 25% !important;
                    left: 35% !important;
                    //margin: 300px 0 0 -280px;
                    outline: unset !important;
                }
            }

        }






        /* FEEDBACK */
        .timestamp {
            vertical-align: middle;
        }
        .enjoy_level {
        }
        .skills_level {
        }
        .campaign_name {
            vertical-align: middle;
        }

        .stars {
            margin-top: 16px;
        }


        /* PLAYER UI - MAIN */

        .chat-content {

            html, body, #root {
                width: 100%;
                height: 100%;
                background-color: rgb(255, 57, 63);
            }


            iframe {
                border-width: 2px !important;
                border-style: solid !important;
                //border-color: rgba(4, 189, 127, 0.7) !important;
                border-color: rgba(114, 137, 218, 0.5) !important;
                //border-image: unset !important;
                //border: none;
                border-radius: 1.0em;
            }  


        }
     
        .css-1fm2mhz-Button-Button-Button-focusable-Join {
            background-color: red;
        }
     


        /* LOGS */
        .message {
            overflow: hidden;
            white-space: nowrap;
        }

        .log-message {
            color: ${({ theme }) => theme.campaigns_topics_text};
            max-width: 100px;
            position: relative;
        }  
    
        .log-message span {
            position: absolute;
            transform: translateX(0);
            transition: 15s;
        }
    
        .log-message:hover span {
            transform: translateX(calc(10px - 100%));
        }
          
        .page-title{
          color: #fff;
          font-size: 1.6rem;
          letter-spacing: normal;
          text-align: left;
        }

        /* New Dashboard */
        .c-collout-dashboard-info{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            
            .c-collout-dashboard-info-item{
                margin-left: 0.3rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 0.2rem;
            }
            .c-collout-dashboard-info-item:first-child{
                margin-left: unset;
            }
            .dboard-info-amount{
                color: rgba(255,255,255,0.8);
            }
            .dboard-info-txt{
                color: rgba(255,255,255,0.5);
            }
            
        }
        .red-info{
            color: ${({ theme }) => theme.red_color} !important;
        }
        .blue-info{
            color: ${({ theme }) => theme.blue_color} !important;
        }
        .green-info{
            color: ${({ theme }) => theme.green_color} !important;
        }
        .gray08-info{
            color: rgba(255,255,255,0.8) !important;
        }
        .gray075-info{
            color: rgba(255,255,255,0.75);
        }
        .gray05-info{
            color: rgba(255,255,255,0.5);
        }
        .gray025-info{
            color: rgba(255,255,255,0.25);
        }
        .test{
           // border: 1px solid red;
        }
       
        .dbrd-readiness-badge-placeholder{
            height: 141px;
            & img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
        }

        svg g g.multi-lines-graph path.domain,
        svg g g.multi-lines-graph line {
            display: none;
        
        }  

        svg g g.multi-lines-graph text{
            fill:rgba(255, 255, 255, 0.25);
        }
        
        .card-cont{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.card_bg};
            padding: 16px;
            flex-wrap: nowrap;
        }


        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: rgba(255, 255, 255, 0.15);
        }

        .medium-editor-placeholder::after,
        .medium-editor-placeholder-relative::after {
            font-style: normal !important;
            color: #707070 !important;
        }

        
         
 
       .grinning_face::before {
    content: "😀";
}

.grinning_face_with_big_eyes::before {
    content: "😃";
}

.grinning_face_with_smiling_eyes::before {
    content: "😄";
}

.beaming_face_with_smiling_eyes::before {
    content: "😁";
}

.grinning_squinting_face::before {
    content: "😆";
}

.grinning_face_with_sweat::before {
    content: "😅";
}

.face_with_tears_of_joy::before {
    content: "😂";
}

.rolling_on_the_floor_laughing::before {
    content: "🤣";
}

.smiling_face_with_smiling_eyes::before {
    content: "😊";
}

.smiling_face_with_halo::before {
    content: "😇";
}

.slightly_smiling_face::before {
    content: "🙂";
}

.upside_down_face::before {
    content: "🙃";
}

.winking_face::before {
    content: "😉";
}

.smiling_face_with_heart_eyes::before {
    content: "😍";
}

.face_blowing_a_kiss::before {
    content: "😘";
}

.kissing_face_with_closed_eyes::before {
    content: "😚";
}

.relieved_face::before {
    content: "😌";
}

.unamused_face::before {
    content: "😒";
}

.disappointed_face::before {
    content: "😞";
}

.crying_face::before {
    content: "😢";
}

.loudly_crying_face::before {
    content: "😭";
}

.face_screaming_in_fear::before {
    content: "😱";
}

.flushed_face::before {
    content: "😳";
}

.face_with_medical_mask::before {
    content: "😷";
}

.dizzy_face::before {
    content: "😵";
}

.smiling_face_with_horns::before {
    content: "😈";
}

.alien::before {
    content: "👽";
}

.alien_monster::before {
    content: "👾";
}

.robot_face::before {
    content: "🤖";
}

.see_no_evil_monkey::before {
    content: "🙈";
}

.hear_no_evil_monkey::before {
    content: "🙉";
}

.speak_no_evil_monkey::before {
    content: "🙊";
}

.dog_face::before {
    content: "🐶";
}

.cat_face::before {
    content: "🐱";
}

.mouse_face::before {
    content: "🐭";
}

.hamster_face::before {
    content: "🐹";
}

.rabbit_face::before {
    content: "🐰";
}

.koala::before {
    content: "🐨";
}

.bear_face::before {
    content: "🐻";
}

.panda_face::before {
    content: "🐼";
}

.tiger_face::before {
    content: "🐯";
}

.horse_face::before {
    content: "🐴";
}

.pig_face::before {
    content: "🐷";
}

.elephant::before {
    content: "🐘";
}

.wolf_face::before {
    content: "🐺";
}

.boar::before {
    content: "🐗";
}

.cow_face::before {
    content: "🐮";
}

.bird::before {
    content: "🐦";
}

.chick::before {
    content: "🐔";
}

.duck::before {
    content: "🦆";
}

.penguin::before {
    content: "🐧";
}

.rooster::before {
    content: "🐓";
}

.hatching_chick::before {
    content: "🐣";
}

.chicken::before {
    content: "🐤";
}

.snake::before {
    content: "🐍";
}

.turtle::before {
    content: "🐢";
}

.butterfly::before {
    content: "🦋";
}

.fish::before {
    content: "🐟";
}

.tropical_fish::before {
    content: "🐠";
}

.blowfish::before {
    content: "🐡";
}

.octopus::before {
    content: "🐙";
}

.spiral_shell::before {
    content: "🐚";
}

.crocodile::before {
    content: "🐊";
}

.leopard::before {
    content: "🐆";
}

.whale::before {
    content: "🐋";
}

.spouting_whale::before {
    content: "🐳";
}

.snail::before {
    content: "🐌";
}

.dragon_face::before {
    content: "🐲";
}

.bactrian_camel::before {
    content: "🐫";
}

.dromedary_camel::before {
    content: "🐪";
}

.bug::before {
    content: "🐛";
}

.ant::before {
    content: "🐜";
}

.honeybee::before {
    content: "🐝";
}

.lady_beetle::before {
    content: "🐞";
}

.cat::before {
    content: "🐈";
}

.dog::before {
    content: "🐕";
}

.monkey::before {
    content: "🐒";
}

.rabbit::before {
    content: "🐇";
}

.monkey_face::before {
    content: "🐵";
}

.poodle::before {
    content: "🐩";
}

.paw_prints::before {
    content: "🐾";
}

.horse::before {
    content: "🐎";
}

.sheep::before {
    content: "🐑";
}

.ram::before {
    content: "🐏";
}

.pig::before {
    content: "🐷";
}

.frog::before {
    content: "🐸";
}

.goat::before {
    content: "🐐";
}

.dragon::before {
    content: "🐉";
}

.tiger::before {
    content: "🐅";
}

.ox::before {
    content: "🐂";
}

.bison::before {
    content: "🐃";
}

.cow::before {
    content: "🐄";
}

.mouse::before {
    content: "🐁";
}

.rat::before {
    content: "🐀";
}

        
    }
`;
